import { Bar, BarChart, LabelList, Legend, XAxis, YAxis } from 'recharts';
import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
} from '../Projects/sharedStyledComponents';

import { useAppSelector } from '../../app/hooks';

const HoursPerModule = (props: { gridClass: string }) => {
  const averageHoursPerModule = useAppSelector(
    (state) => state.selectedProject.classHoursPerModule,
  );

  const heightSize = 60 * averageHoursPerModule.length;
  const tickFormatter = (value: string) => {
    const limit = 18;
    if (value.length < limit) return value;
    return value.toString().replace(/-/g, ' ');
  };

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph width="full">
        <StyledGraphHeader>
          <StyledGraphTitle>
            Hours Spent Per Project and Related Primers
          </StyledGraphTitle>
        </StyledGraphHeader>

        <div style={{ height: '60vh', overflowY: 'auto' }}>
          <BarChart
            barSize={20}
            barGap={0}
            margin={{ right: 20 }}
            data={averageHoursPerModule}
            layout="vertical"
            style={{
              fontSize: '.8rem',
              fill: `rgba(19, 44, 62, 0.7)`,
            }}
            width={450}
            height={heightSize}
          >
            <Legend
              formatter={(value: string) => {
                return (
                  <span style={{ color: 'rgba(19, 44, 62, 0.7)' }}>
                    {value}
                  </span>
                );
              }}
              verticalAlign="top"
              align="left"
              iconSize={8}
              iconType={'circle'}
              wrapperStyle={{ paddingBottom: '1rem', marginLeft: '1rem ' }}
            />
            <XAxis type="number" hide />
            <YAxis
              dx={-130}
              tick={{ textAnchor: 'start' }}
              width={150}
              type="category"
              dataKey="module"
              style={{
                fontSize: '.8rem',
                fill: `rgba(19, 44, 62, 0.7)`,
                paddingRight: '.5rem',
              }}
              axisLine={false}
              tickLine={false}
              tickFormatter={tickFormatter}
            />
            <Bar name="Student" dataKey="studentHours" fill="#2071DE">
              <LabelList
                dataKey="studentHours"
                position="right"
                style={{ fontSize: '.8rem', fill: `#2071DE` }}
              />
            </Bar>
            <Bar name="Class Average" dataKey="avg" fill="#DCDFE2">
              <LabelList
                dataKey="avg"
                position="right"
                style={{ fontSize: '.8rem', fill: `rgba(19, 44, 62, 0.7)` }}
              />
            </Bar>
          </BarChart>
        </div>
      </StyledGraph>
    </StyledGraphContainer>
  );
};

export default HoursPerModule;
