import {
  navbarActiveCourse,
  navbarActiveProject,
} from '../../../app/navbarSlice';

import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';

const Timeline = () => {
  const project = useAppSelector(navbarActiveProject);
  const course = useAppSelector(navbarActiveCourse);
  const startDate = new Date(project.date_to_activate); //.split("T")[0]);
  const endDate = new Date(project.date_to_submit); //endDate.split("T")[0]);

  let today = new Date();
  if (course.label === 'sail-ppp-dashboard-demo') {
    // console.dir(project);
    today = new Date('07/15/2022');
    // console.dir(today);
  }
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  today = new Date(mm + '/' + dd + '/' + yyyy);

  const numberOfDays =
    Math.ceil(calculateNumberOfDays(startDate, endDate)) || 0;
  const numberOfDaysIn = Math.ceil(calculateNumberOfDays(startDate, today)) + 1;
  const numberOfDaysLeft = Math.floor(numberOfDays - numberOfDaysIn);

  const daysInText =
    numberOfDaysIn === 1
      ? `${numberOfDaysIn} day in`
      : `${numberOfDaysIn} days in`;

  const daysLeftText =
    numberOfDaysLeft === 1
      ? `${numberOfDaysLeft} day left`
      : numberOfDaysLeft > 0
        ? `${numberOfDaysLeft} days left`
        : numberOfDaysLeft === 0
          ? `due today`
          : Math.floor(Math.abs(numberOfDaysLeft)) === 1
            ? `${Math.floor(Math.abs(numberOfDaysLeft))} day past deadline`
            : `${Math.floor(Math.abs(numberOfDaysLeft))} days past deadline`;

  const timelineWidth = 80;

  function calculateNumberOfDays(start: Date, end: Date) {
    const Difference_In_Time = end.getTime() - start.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  }

  const singleDayWidth = 100 / numberOfDays;

  function getMonthAndDay(date: Date) {
    let month = '';
    switch (date.getMonth()) {
      case 0:
        month = 'Jan.';
        break;
      case 1:
        month = 'Feb.';
        break;
      case 2:
        month = 'Mar.';
        break;
      case 3:
        month = 'Apr.';
        break;
      case 4:
        month = 'May';
        break;
      case 5:
        month = 'Jun.';
        break;
      case 6:
        month = 'Jul.';
        break;
      case 7:
        month = 'Aug.';
        break;
      case 8:
        month = 'Sep.';
        break;
      case 9:
        month = 'Oct.';
        break;
      case 10:
        month = 'Nov.';
        break;
      case 11:
        month = 'Dec.';
        break;
    }
    return `${month} ${date.getDate()}`;
  }

  return (
    <StyledTimeline>
      <Row width={timelineWidth}>
        {numberOfDaysLeft < 0 ? <div></div> : <div>{daysInText}</div>}
        <div>{daysLeftText}</div>
      </Row>
      <Row width={0}>
        <StyledDate>{getMonthAndDay(startDate)}</StyledDate>
        <AllDays width={timelineWidth}>
          {[...Array(numberOfDays)].map((value: undefined, index: number) => (
            <StyledSingleDay
              width={singleDayWidth}
              key={index}
              daysIn={index < numberOfDaysIn ? true : false}
            />
          ))}
        </AllDays>
        <StyledDate> {getMonthAndDay(endDate)}</StyledDate>
      </Row>
    </StyledTimeline>
  );
};

export default Timeline;

const StyledTimeline = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding-bottom: 3%;
`;

const Row = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  &:first-child {
    color: rgba(19, 44, 62, 0.6);
    font-size: 0.75rem;
    padding-left: 10%;
    width: ${(props) => props.width}%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

const AllDays = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSingleDay = styled.div<{ width: number; daysIn: boolean }>`
  background-color: ${(props) =>
    props.daysIn ? '#2071de' : 'rgba(19, 44, 62, 0.15)'};
  width: ${(props) => props.width - 0.6}%;
`;

const StyledDate = styled.div`
  width: 3rem;
  padding-right: 2%;
  text-align: start;
  &:last-child {
    text-align: end;
    padding: 0 0 0 2%;
  }
`;
