import {theme} from "../../../sharedStyles/Theme";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    topSuspiciousFilterSetting,
    updateNavBarCasesByFilter,
    updateTopSuspiciousFilterSetting
} from "../../../app/navbarQuizCheatCheckingSlice";
import {Button} from "@material-ui/core";
import {Container} from "../../Selector";

export const TopSuspiciousFilterSetting = () => {
    const reduxDispatch = useAppDispatch();
    const topSuspiciousFilterSettingRedux = useAppSelector(topSuspiciousFilterSetting);

    const handleOverLapPagesTopPercentChange = (event: any) => {
        const newSetting = {...topSuspiciousFilterSettingRedux}
        newSetting['numberOfOverlapPages'] = event.target.value;
        reduxDispatch(
          updateTopSuspiciousFilterSetting(newSetting)
        );
    }

    const handleSyncPagesTopPercentChange = (event: any) => {
        const newSetting = {...topSuspiciousFilterSettingRedux}
        newSetting['numberOfSyncPages'] = event.target.value;
        reduxDispatch(
            updateTopSuspiciousFilterSetting(newSetting)
        );
    }

    const handleOverLapPercentTopPercentChange = (event: any) => {
        const newSetting = {...topSuspiciousFilterSettingRedux}
        newSetting['overlapPercentage'] = event.target.value;
        reduxDispatch(
            updateTopSuspiciousFilterSetting(newSetting)
        );
    }

    const handleOverLapSecondsTopPercentChange = (event: any) => {
        const newSetting = {...topSuspiciousFilterSettingRedux}
        newSetting['overlapSeconds'] = event.target.value;
        reduxDispatch(
            updateTopSuspiciousFilterSetting(newSetting)
        );
    }

    const applyFilterSettings = () => {
        reduxDispatch(updateNavBarCasesByFilter());
    }

    return (
        <Container height='13rem'>
            <span
                style={{width: "100%", height: "1rem", alignItems: "center", textAlign: 'center', display: "inline-block",
                    margin: '2% 0 4% 0'}}
            >Top Percentage Settings</span>
            <label
                style={{width: "96%", height: "2rem", display: "flex", justifyContent: "space-between",
                    alignItems: "center", fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)', padding: '0 2% 0 2%'}}
            >
                Overlap Pages
                <input
                    value={topSuspiciousFilterSettingRedux['numberOfOverlapPages']}
                    onChange={handleOverLapPagesTopPercentChange}
                    style={{width: "50%", height: '60%', fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)'}}
                />
            </label>
            <label
                style={{width: "96%", height: "2rem", display: "flex", justifyContent: "space-between",
                    alignItems: "center", fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)', padding: '0 2% 0 2%'}}
            >
                Sync Pages
                <input
                    value={topSuspiciousFilterSettingRedux['numberOfSyncPages']}
                    onChange={handleSyncPagesTopPercentChange}
                    style={{width: "50%", height: '60%', fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)'}}
                />
            </label>
            <label
                style={{width: "96%", height: "2rem", display: "flex", justifyContent: "space-between",
                    alignItems: "center", fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)', padding: '0 2% 0 2%'}}
            >
                Overlap Percent
                <input
                    value={topSuspiciousFilterSettingRedux['overlapPercentage']}
                    onChange={handleOverLapPercentTopPercentChange}
                    style={{width: "50%", height: '60%', fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)'}}
                />
            </label>
            <label
                style={{width: "96%", height: "2rem", display: "flex", justifyContent: "space-between",
                    alignItems: "center", fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)', padding: '0 2% 0 2%'}}
            >
                Overlap Seconds
                <input
                    value={topSuspiciousFilterSettingRedux['overlapSeconds']}
                    onChange={handleOverLapSecondsTopPercentChange}
                    style={{width: "50%", height: '60%', fontSize: '14px', color: 'rgba(19, 44, 62, 0.75)'}}
                />
            </label>
            <Button
                style={{width: '100%', height: '16%', backgroundColor: theme.colors.white, margin: '3% 0 0 0',
                    color: 'rgba(19, 44, 62, 0.75)'}}
                onClick={applyFilterSettings}
            >
                Apply
            </Button>
        </Container>
    )
}
