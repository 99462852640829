import {
  navbarActiveCourse,
  navbarActiveProject,
  navbarComponentError,
  navbarStudents,
} from '../../app/navbarSlice';
import {
  snapshotMaxScore,
  snapshotNonSubmitters,
  snapshotPageError,
  snapshotPageStatus,
  snapshotPreviousProjects,
  snapshotStudents,
} from '../../app/snapshotSlice';

import styled from 'styled-components';
import { useAppSelector } from '../../app/hooks';
import ProjectsNavBar from '../../components/Projects/ProjectsNavBar';
import SnapshotCard from '../../components/Projects/Snapshot/SnapshotCard';
import SnapshotTable from '../../components/Projects/Snapshot/SnapshotTable';
import Timeline from '../../components/Projects/Snapshot/Timeline';
import { StyledGrid } from '../../components/Projects/sharedStyledComponents';
import Theme from '../../sharedStyles/Theme';
import { LoadingLogo } from '../../svgs/loading-logo';
import Error from '../Error';

const Snapshot = () => {
  const pageStatus = useAppSelector(snapshotPageStatus);
  const enrolledStudents = useAppSelector(navbarStudents);
  const students = useAppSelector(snapshotStudents);
  const previousProjects = useAppSelector(snapshotPreviousProjects);
  const nonSubmitters = useAppSelector(snapshotNonSubmitters);
  const submitters = students.filter((obj) =>
    Object.keys(obj).includes('nSubmissions'),
  );
  const maxScore = useAppSelector(snapshotMaxScore);
  const completedStudents = students.filter(function (e: any) {
    return e.module_score >= maxScore;
  });
  const inactiveStudents = students.filter(function (e: any) {
    return e.hoursspent === 0;
  });

  const courseValue = useAppSelector(navbarActiveCourse).value;
  const project = useAppSelector(navbarActiveProject);
  const pageError = useAppSelector(snapshotPageError);
  const componentError = useAppSelector(navbarComponentError);

  const snapshotCards = [
    {
      title: 'Total Enrollment',
      description: 'Students Initially Enrolled',
      students: enrolledStudents,
    },
    {
      title: "Haven't Started",
      description: 'Not Read Project Writeup',
      students: inactiveStudents,
    },
    {
      title: 'Have Submitted',
      description: 'Made a Submission',
      students: submitters,
    },
    {
      title: 'Have Completed',
      description: 'Earned the Maximum Score',
      students: completedStudents,
    },
  ];

  return courseValue === '404' || componentError ? (
    <Error errorType={404} />
  ) : pageError ? (
    <Error errorType={500} errorMessage={pageError} />
  ) : (
    <Theme>
      <ProjectsNavBar />
      {!pageStatus ? (
        <LoadingLogo />
      ) : (
        <StyledSnapshot>
          <SnapshotCards>
            {snapshotCards.map((snapshot, index) => (
              <SnapshotCard
                key={index}
                title={snapshot.title}
                description={snapshot.description}
                students={snapshot.students}
              />
            ))}
          </SnapshotCards>
          <ProjectTimeline>
            <SectionTitle>Project Timeline</SectionTitle>
            <Timeline />
          </ProjectTimeline>
          <SnapshotTable
            students={nonSubmitters}
            type={'Non-Submitters'}
            title={'Students Who Have Not Submitted'}
            courseValue={courseValue}
            previousProjects={previousProjects}
            project={project}
          />
          <SnapshotTable
            students={submitters}
            type={'Submitters'}
            title={'Students Who Have Made a Submission'}
            courseValue={courseValue}
            project={project}
          />
        </StyledSnapshot>
      )}
    </Theme>
  );
};

export default Snapshot;

const SnapshotCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ProjectTimeline = styled.div`
  background-color: white;
  padding: 1.5rem 2rem 0 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
`;

const StyledSnapshot = styled(StyledGrid)`
  grid-template-columns: repeat(2, 49%);
  grid-template-rows: 1fr 4fr;
  grid-column-gap: 2%;
  grid-row-gap: 3rem;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr 4fr 4fr;
  }
`;

const SectionTitle = styled.div`
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;
