import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
  StyledNoDataResponse,
} from '../Projects/sharedStyledComponents';
import {
  navbarActiveProject,
  navbarActiveStudent,
} from '../../app/navbarSlice';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useAppSelector } from '../../app/hooks';

const Plot = createPlotlyComponent(Plotly);

const ScorePerSubmission = (props: { gridClass: string }) => {
  const scorePerSubmission = useAppSelector(
    (state) => state.selectedProject.scorePerSubmission,
  );
  const project = useAppSelector(navbarActiveProject);
  const activeStudent = useAppSelector(navbarActiveStudent);

  const colors = [
    '#CD2C8A',
    '#FDA529',
    '#6C0B44',
    '#FDCE29',
    '#FFC2E6',
    '#FF83CB',
    '#FFC2E6',
    '#ACEAFF',
    '#409DBA',
    '#204E5D',
    '#FDA529',
    '#FDCE29',
  ];

  const tasks = Array.from(
    new Set(scorePerSubmission.map((s: { task_slug: any }) => s.task_slug)),
  );
  const data: any[] = tasks.map((t, index) => {
    const subset = scorePerSubmission.filter(
      (s: { task_slug: unknown }) => s.task_slug === t,
    );
    return {
      x: subset.map((s: { event_time: any }) => new Date(s.event_time)),
      y: subset.map((s: { score: any }) => s.score),
      marker: { color: colors[index], size: 10 },
      mode: 'lines+markers',
      name: t,

      type: 'scatter',
      hovertemplate: `Score: %{y} <br /> Submitted: %{x}`,
    };
  });

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph width={'full'}>
        <StyledGraphHeader>
          <StyledGraphTitle>
            Project Tasks - Score Per Submission
          </StyledGraphTitle>
        </StyledGraphHeader>
        {data[0] ? (
          <Plot
            data={data}
            layout={{
              font: {
                family: 'Fira Sans, sans-serif',
                size: 12,
              },
              autosize: true,
              hovermode: 'closest',
              margin: { l: 300, r: 100, b: 50, t: 25 },
              showlegend: true,
              legend: {
                x: -0.5,
                y: 1,
              },
              xaxis: {
                range: [
                  new Date(project.date_to_activate),
                  new Date(project.date_to_submit),
                ],
                hoverformat: '%m/%d %I:%M %p',
              },
              yaxis: {
                title: {
                  text: 'Score (pts)',
                },
                range: [0, project.max_score || 100],
              },
            }}
            style={{ width: '100%', height: '90%' }}
            useResizeHandler
            config={{
              modeBarButtonsToRemove: [
                'lasso2d',
                'select2d',
                'sendDataToCloud',
                'autoScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'hoverClosestGl2d',
                'toggleSpikelines',
              ],
            }}
          />
        ) : (
          <StyledNoDataResponse>
            {activeStudent.label} has not yet made any submissions for this
            project.{' '}
          </StyledNoDataResponse>
        )}
      </StyledGraph>
    </StyledGraphContainer>
  );
};

export default ScorePerSubmission;
