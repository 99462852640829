import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import allProjectsReducer from './allProjectsSlice';
import navbarReducer from './navbarSlice';
import selectedProjectReducer from './selectedProjectSlice';
import snapshotReducer from './snapshotSlice';
import trendReducer from './trendSlice';
import navbarCheatCheckingReducer from './navbarQuizCheatCheckingSlice';
import quizCheatCheckingReducer from './quizCheatCheckingSlice';

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    navbarQuizCheatChecking: navbarCheatCheckingReducer,
    trend: trendReducer,
    selectedProject: selectedProjectReducer,
    snapshot: snapshotReducer,
    allProjects: allProjectsReducer,
    quizCheatChecking: quizCheatCheckingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
