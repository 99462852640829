import { AppThunk, RootState } from './store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface NavbarState {
  showHamburgerMenu: boolean;
  activeCourse: SelectOption;
  projects: any;
  primers: any;
  activeProject: any;
  students: any;
  activeStudent: any;
  lastUpdatedTimestamp: any;
  componentError: string;
  token: any;
}

const initialState: NavbarState = {
  showHamburgerMenu: false,
  activeCourse: {
    value: '',
    label: '',
  },
  projects: [],
  token: '',
  primers: [],
  activeProject: {},
  students: [],
  activeStudent: {},
  lastUpdatedTimestamp: '',
  componentError: '',
};

interface SelectOption {
  value: string;
  label: string;
}

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggleHamburgerMenu: (state) => {
      state.showHamburgerMenu = !state.showHamburgerMenu;
    },
    updateJWTToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload.value;
    },
    updateActiveCourse: (state, action: PayloadAction<any>) => {
      const value = action.payload.value;
      const label = action.payload.metadata
        ? action.payload.metadata.results.name
        : value;
      const course = {
        value: value,
        label: label,
      };

      state.activeCourse = course;
    },
    updateProjects: (state, action: PayloadAction<any>) => {
      const projects = action.payload.projects.map((project: any) => {
        return project;
      });
      state.projects = projects;

      const project = action.payload.project;

      state.activeProject = project
        ? projects.find(
            (e: { value: any }) => e.value === action.payload.project,
          ) || state.projects[0]
        : state.projects[0];
    },
    updatePrimers: (state, action: PayloadAction<any>) => {
      state.primers = action.payload;
    },
    updateStudents: (state, action: PayloadAction<any>) => {
      state.students = action.payload;
      state.activeStudent = state.students[0];
    },
    updateActiveProject: (state, action: PayloadAction<any>) => {
      const project = action.payload;
      state.activeProject = project;

      const splitURL = window.location.pathname.split('/');
      splitURL[2] = project.value;
      window.history.pushState(
        { project: 'Update project' },
        'Dashboard',
        splitURL.join('/'),
      );
    },
    updateActiveStudent: (state, action: PayloadAction<any>) => {
      state.activeStudent = action.payload;
    },
    updateTimestamp: (state, action: PayloadAction<any>) => {
      state.lastUpdatedTimestamp = action.payload[0].lastUpdated;
    },
    updateComponentError: (state, action: PayloadAction<any>) => {
      state.componentError = action.payload;
    },
  },
});

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

// ****************
// APIs
// ****************
const { REACT_APP_BACKEND_URI } = process.env;

export const getProjectsAndStudentsPerCourse =
  (course: any, project: string): AppThunk =>
  (dispatch) => {
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);

    fetch(`${REACT_APP_BACKEND_URI}/api/${course}/generate_token`, {
      method: 'GET',
      headers: headers,
    })
      .then((response: any) => {
        return response.json();
      })
      .then((response: any) => {
        localStorage.setItem('jwt', JSON.stringify(response.token));
      });

    Promise.all([
      fetch(`${REACT_APP_BACKEND_URI}/api/${course}/meta/courseName`, {
        method: 'GET',
        headers: headers,
      }),
      fetch(`${REACT_APP_BACKEND_URI}/api/${course}/meta/lastUpdated`, {
        method: 'GET',
        headers: headers,
      }),
      fetch(`${REACT_APP_BACKEND_URI}/api/${course}/meta/projects`, {
        method: 'GET',
        headers: headers,
      }),
      fetch(`${REACT_APP_BACKEND_URI}/api/${course}/meta/primers`, {
        method: 'GET',
        headers: headers,
      }),
      fetch(`${REACT_APP_BACKEND_URI}/api/${course}/meta/students`, {
        method: 'GET',
        headers: headers,
      }),
    ])
      .then((responses) => {
        return Promise.all(
          responses.map(async function (response) {
            if (response.ok) return response.json();
            else {
              const errorText = await response.text();
              throw new Error(`${errorText} for ${response.url}`);
            }
          }),
        );
      })
      .then((jsonData) => {
        jsonData.forEach(function (json, index) {
          switch (index) {
            case 0:
              dispatch(updateActiveCourse({ value: course, metadata: json }));
              break;
            case 1:
              dispatch(updateTimestamp(json));
              break;
            case 2:
              json = json.map(function (obj: any) {
                obj['value'] = obj['slug'];
                obj['label'] = obj['module_name'];

                delete obj['slug'];
                delete obj['module_name'];
                return obj;
              });
              project && project !== 'students' && project !== 'projects'
                ? dispatch(updateProjects({ projects: json, project: project }))
                : dispatch(updateProjects({ projects: json }));
              break;
            case 3:
              json = json.map(function (obj: any) {
                obj['value'] = obj['slug'];
                obj['label'] = obj['module_name'];

                delete obj['slug'];
                delete obj['module_name'];
                return obj;
              });
              dispatch(updatePrimers(json));
              break;
            case 4:
              json = json.map(function (obj: any) {
                obj['value'] = obj['email'];
                obj['label'] = obj['full_name'] || obj['email'].split('@')[0];
                delete obj['email'];
                delete obj['full_name'];
                return obj;
              });
              dispatch(updateStudents(json));
              break;
          }
        });
      })
      .catch((error) => {
        dispatch(updateComponentError(error.toString()));
      });
  };

// **************************************************************
// Exports
// **************************************************************
export const {
  toggleHamburgerMenu,
  updateActiveCourse,
  updateActiveProject,
  updateProjects,
  updatePrimers,
  updateStudents,
  updateActiveStudent,
  updateTimestamp,
  updateComponentError,
  updateJWTToken,
} = navbarSlice.actions;

export const navbarActiveProject = (state: RootState) =>
  state.navbar.activeProject;
export const navbarStudents = (state: RootState) => state.navbar.students;
export const navbarActiveStudent = (state: RootState) =>
  state.navbar.activeStudent;
export const navbarActiveCourse = (state: RootState) =>
  state.navbar.activeCourse;
export const navbarProjects = (state: RootState) => state.navbar.projects;
export const navbarPrimers = (state: RootState) => state.navbar.primers;

export const navbarComponentError = (state: RootState) =>
  state.navbar.componentError;

export default navbarSlice.reducer;
