import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TaskSubmissionChart = (props: { data: any, header: any, taskNames: string[] }) => {
  // Define a list of colors to assign to each line
  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#387908", "#00c49f"];

  // Track visibility of lines (default is all visible)
  const [visibleLines, setVisibleLines] = useState(props.taskNames.reduce((acc, taskName) => {
    acc[taskName] = true;
    return acc;
  }, {}));

  // Toggle visibility of a line when the legend is clicked
  const handleLegendClick = (taskName) => {
    setVisibleLines(prevState => ({
      ...prevState,
      [taskName]: !prevState[taskName],  // Toggle visibility
    }));
  };

  return (
    <div>
      {props.header && <h2 style={{ textAlign: 'center' }}>{props.header}</h2>}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={props.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hour" />
          <YAxis />
          <Tooltip />
          <Legend onClick={(e) => handleLegendClick(e.value)} />
          {props.taskNames.map((taskName, index) => (
            <Line
              key={taskName}
              type="monotone"  // Ensure lines connect all dots smoothly
              dataKey={taskName}
              stroke={colors[index % colors.length]}  // Assign color to each line
              activeDot={{ r: 8 }}
              connectNulls={true}  // Connect all points, even if there are missing values
              hide={!visibleLines[taskName]}  // Hide line if the corresponding legend is clicked
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TaskSubmissionChart;