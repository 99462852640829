import Theme from "../../sharedStyles/Theme";
import ClassicNavBar from '../../components/Classic/ClassicNavBar';
import {LoadingLogo} from "../../svgs/LoadingLogo";
import React, { useEffect, useState } from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import { navbarActiveCourse, navbarStudents, navbarProjects, navbarActiveProject } from "../../app/navbarSlice";
import AllProjectBarChart from "./AllProjectBarChart";


const { REACT_APP_BACKEND_URI } = process.env;

function getStorageValue(key: string, defaultValue: string) {
// getting stored value
    if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
    }
}

const calculateMeanScores = (scores) => {
    const groupedScores = {};

    // Grouping scores by module_name
    scores.forEach((scoreData) => {
      const { module_name, score } = scoreData;
      if (!groupedScores[module_name]) {
        groupedScores[module_name] = [];
      }
      groupedScores[module_name].push(score);
    });

    // Calculating mean scores for each module_name
    const meanScores = {};
    for (const moduleName in groupedScores) {
      const totalScore = groupedScores[moduleName].reduce((acc, curr) => acc + curr, 0);
      const meanScore = totalScore / groupedScores[moduleName].length;
      meanScores[moduleName] = Math.round(meanScore * 100) / 100; // Round to 2 decimal points
    }
    return meanScores;
};

// Helper function
function getMedian(arr) {
  const sorted = arr.slice().sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);

  if (sorted.length % 2 !== 0) {
    return sorted[mid];
  } else {
    return (sorted[mid - 1] + sorted[mid]) / 2;
  }
}

const calculateMedianScores = (scores) =>{
  
  const groupedData = scores.reduce((acc, item) => {
    const { module_name, score } = item;
    if (!acc[module_name]) {
      acc[module_name] = [];
    }
    acc[module_name].push(score);
    return acc;
  }, {});

  const medianScores = Object.keys(groupedData).reduce((acc, module) => {
    acc[module] = getMedian(groupedData[module]);
    return acc;
  }, {});

  return medianScores;
}


const groupSubmissionByProject = (submissions) => {
  const groupedData = submissions.reduce((acc, curr) => {
      if (!acc[curr.module_name]) {
          acc[curr.module_name] = 0;
      }
      acc[curr.module_name] += curr.nSubmissions;
      return acc;
  }, {});
  return groupedData
}

const groupSubmissionByAssessme = (submissions) => {
  const groupedData = submissions.reduce((acc, curr) => {
      if (!acc[curr.module_name]) {
          acc[curr.module_name] = 0;
      }
      acc[curr.module_name] += curr.num_students;
      return acc;
  }, {});
  return groupedData
}

const groupSubmitterByProject = (submissions) => {
  const submitterCounts = submissions.reduce((acc, curr) => {
      if (!acc[curr.module_name]) {
          acc[curr.module_name] = new Set();
      }
      acc[curr.module_name].add(curr.email);
      return acc;
  }, {});

  const result = Object.entries(submitterCounts).reduce((acc, [module_name, emails]) => {
    acc[module_name] = emails.size;
    return acc;
  }, {});
  
  return result
}

const AllProjectsClassic = () => {

    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    const courseValue = useAppSelector(navbarActiveCourse).value;


    const [allMeanProjectScores, setAllMeanProjectScores] = useState({});
    const [allMedianProjectScores, setAllMedianProjectScores] = useState({});
    const [submissionPerProject, setSubmissionPerProject] = useState({});
    const [submitterPerProject, setSubmitterPerProject] = useState({});
    const [allAssessmeSubmissions, setAllAssessmeSubmissions] = useState({});
    const [IsAssessmeSubmissionReady, setIsAssessmeSubmissionReady] = useState("");



    useEffect(() => {

        const fetchSubmission = async () => {
          try {
            const response = await fetch(`${REACT_APP_BACKEND_URI}/api/${courseValue}/course/nSubmissions`, {
              method: 'GET',
              headers: headers,
            });
            if (response.ok) {
              const submissionResponse = await response.json();
              console.log("[Submission response]", submissionResponse); // [{email, module_score}]
              const submissions = groupSubmissionByProject(submissionResponse)
              console.log("[Submission response]", submissions); // [{email, module_score}]
              const submitters = groupSubmitterByProject(submissionResponse)
              console.log("[submitters]", submitters); // [{email, module_score}]
              setSubmissionPerProject(submissions)
              setSubmitterPerProject(submitters)
              // const meanScores = calculateMeanScores(scoreResponse)
              // const medianScores = calculateMedianScores(scoreResponse)
              // console.log("[meanScores]", meanScores); 
              // console.log("[medianScores]", medianScores); 
              // setAllMeanProjectScores(meanScores)
              // setAllMedianProjectScores(medianScores)
            } else {
              console.error('Error fetching submission status:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching submissions:', error);
          }
        }

        const fetchAllAssessMeSubmission = async () => {
          try {
            setIsAssessmeSubmissionReady("loading");
            const response = await fetch(`${REACT_APP_BACKEND_URI}/api/${courseValue}/stats/assessme`, {
              method: 'GET',
              headers: headers,
            });
            if (response.ok) {
              const assessmeSubmissionResponse = await response.json();
              if ("status" in assessmeSubmissionResponse && assessmeSubmissionResponse.status === "false") {
                setIsAssessmeSubmissionReady("not enabled");
              }             
              setAllAssessmeSubmissions(groupSubmissionByAssessme(assessmeSubmissionResponse));
              setIsAssessmeSubmissionReady("ready");
              console.log("[Assessme submission response]", assessmeSubmissionResponse); // [{email, module_score}]
            } else {
              console.error('Error fetching assessme submission status:', response.statusText);
              setIsAssessmeSubmissionReady("not enabled");
            }
          } catch (error) {
            console.error('Error fetching assessme submissions:', error);
            setIsAssessmeSubmissionReady("not enabled");
          }
        }



        const fetchAllProjectScores = async () => {
            try {
                const response = await fetch(`${REACT_APP_BACKEND_URI}/api/${courseValue}/course/scores`, {
                  method: 'GET',
                  headers: headers,
                });
                if (response.ok) {
                  const scoreResponse = await response.json();
                  console.log("[Score response]", scoreResponse); // [{email, module_score}]
                  const meanScores = calculateMeanScores(scoreResponse)
                  const medianScores = calculateMedianScores(scoreResponse)
                  console.log("[meanScores]", meanScores); 
                  console.log("[medianScores]", medianScores); 
                  setAllMeanProjectScores(meanScores)
                  setAllMedianProjectScores(medianScores)
                } else {
                  console.error('Error fetching scores status:', response.statusText);
                }
              } catch (error) {
                console.error('Error fetching scores:', error);
              }
        }



        if (courseValue) {
            fetchAllProjectScores();
            fetchSubmission();
            fetchAllAssessMeSubmission();
        }
    },[courseValue])


    return (
        <Theme>
            <ClassicNavBar type="All Project"/>
            {IsAssessmeSubmissionReady === 'ready' ? (
              <AllProjectBarChart 
                data={allAssessmeSubmissions} 
                type="num_students" 
                header="Project Assessme Completion status" 
              />
            ) : IsAssessmeSubmissionReady === 'loading' ? (
              <LoadingLogo />
            ) : IsAssessmeSubmissionReady === 'not enabled' ? (
              null
            ) : null}
            { Object.keys(allMedianProjectScores).length > 0 ? <AllProjectBarChart data={submitterPerProject} type="nSubmitters" header="Number of submitters"/> : <LoadingLogo/>}
            <br/>
            { Object.keys(allMedianProjectScores).length > 0 ? <AllProjectBarChart data={submissionPerProject} type="submission" header="Number of submissions"/> : <LoadingLogo/>}
            <br/>
            { Object.keys(allMeanProjectScores).length > 0 ? <AllProjectBarChart data={allMeanProjectScores} type="mean_score" header="Mean autograded score"/> : <LoadingLogo/>}
            <br/>
            { Object.keys(allMedianProjectScores).length > 0 ? <AllProjectBarChart data={allMedianProjectScores} type="median_score" header="Median autograded score"/> : <LoadingLogo/>}
            <br/>
        </Theme>
    );
};

export default AllProjectsClassic;