import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../app/hooks';

const Timeline = (props: any) => {
  const project = props.project;

  const submissions = useAppSelector(
    (state) => state.allProjects.submissionsPerProject[project.value],
  );

  const noData = submissions ? false : true;

  let firstSubmission =
    submissions && submissions[0] && submissions[0].event_time.split('T')[0];
  let lastSubmission =
    submissions &&
    submissions[submissions.length - 1] &&
    submissions[submissions.length - 1].event_time.split('T')[0];
  firstSubmission = new Date(firstSubmission);
  lastSubmission = new Date(lastSubmission);

  const startDate = new Date(project.date_to_activate); //.split("T")[0]);
  const endDate = new Date(project.date_to_submit); //endDate.split("T")[0]);

  const numberOfDays =
    Math.ceil(calculateNumberOfDays(startDate, endDate)) || 0;
  const timelineWidth = 80;
  const singleDayWidth = 100 / numberOfDays - 1.5;

  let firstSubmissionTick = Math.floor(
    calculateNumberOfDays(startDate, firstSubmission),
  );
  firstSubmissionTick = firstSubmissionTick < 0 ? 0 : firstSubmissionTick;

  let lastSubmissionTick = Math.floor(
    calculateNumberOfDays(startDate, lastSubmission),
  );
  lastSubmissionTick =
    lastSubmissionTick >= numberOfDays ? numberOfDays : lastSubmissionTick;

  function calculateNumberOfDays(start: Date, end: Date) {
    const Difference_In_Time = end.getTime() - start.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  }
  function getMonthAndDay(date: Date) {
    let month = '';
    switch (date.getMonth()) {
      case 0:
        month = 'Jan.';
        break;
      case 1:
        month = 'Feb.';
        break;
      case 2:
        month = 'Mar.';
        break;
      case 3:
        month = 'Apr.';
        break;
      case 4:
        month = 'May';
        break;
      case 5:
        month = 'Jun.';
        break;
      case 6:
        month = 'Jul.';
        break;
      case 7:
        month = 'Aug.';
        break;
      case 8:
        month = 'Sep.';
        break;
      case 9:
        month = 'Oct.';
        break;
      case 10:
        month = 'Nov.';
        break;
      case 11:
        month = 'Dec.';
        break;
    }
    return month ? `${month} ${date.getDate()}` : noData ? 'Loading' : 'None';
  }
  return (
    <StyledTimeline>
      <StyledProjectLabel>{project.label}</StyledProjectLabel>
      <div>
        <Row width={timelineWidth}>
          {firstSubmission && (
            <div>First Submission: {getMonthAndDay(firstSubmission)}</div>
          )}
        </Row>
        <Row width={0}>
          <StyledDate>{getMonthAndDay(startDate)}</StyledDate>
          <AllDays width={timelineWidth}>
            {[...Array(numberOfDays)].map((value: undefined, index: number) => (
              <StyledSingleDay width={singleDayWidth} key={index}>
                {(firstSubmissionTick === index ||
                  lastSubmissionTick === index) && (
                  <StyledActiveDay width={singleDayWidth} key={index} />
                )}
              </StyledSingleDay>
            ))}
          </AllDays>
          <StyledDate> {getMonthAndDay(endDate)}</StyledDate>
        </Row>
        <Row width={timelineWidth}>
          <StyledLastSubmission>
            Last Submission: {getMonthAndDay(lastSubmission)}
          </StyledLastSubmission>
        </Row>
      </div>
    </StyledTimeline>
  );
};

export default Timeline;

const StyledTimeline = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 1%;
  font-size: 0.875rem;

  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;
`;

const Row = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
    &:first-child {
      color: rgba(19, 44, 62, 0.6);
      font-size: 0.75rem;
      padding-left: 10%;
      width: ${(props) => props.width}%};
      justify-content: space-between;
      margin-bottom: .5rem;
    }
    &:last-child {
      color: rgba(19, 44, 62, 0.6);
      font-size: 0.75rem;
      padding-left: 10%;
      width: ${(props) => props.width}%};
      justify-content: space-between;
      margin-bottom: .5rem;
    }
`;

const StyledLastSubmission = styled.div`
  margin-left: auto;
`;

const AllDays = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;
`;

const StyledSingleDay = styled.div<{ width: number }>`
  background-color: rgba(19, 44, 62, 0.15);
  width: ${(props) => props.width}%;
`;

const StyledActiveDay = styled.div<{ width: number }>`
  background-color: #2071de;
  width: 1rem;
  z-index: 10;
  position: absolute;
  height: 100%;
`;

const StyledDate = styled.div`
  width: 3rem;
  padding-right: 2%;
  text-align: start;
  &:last-child {
    text-align: end;
    padding: 0 0 0 2%;
  }
  font-size: 0.8rem;
`;

const StyledProjectLabel = styled.div`
  font-size: 0.8rem;
  color: rgba(19, 44, 62, 0.7);
`;
