import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  navbarActiveCourse,
  navbarActiveProject,
  navbarActiveStudent,
  navbarComponentError,
  navbarPrimers,
} from '../../app/navbarSlice';
import {
  clearActiveStudent,
  getClassHoursPerModule,
  getStudentDataForSelectedProject,
  selectedProjectActivityByEventLoading,
  selectedProjectHoursPerModuleLoading,
  selectedProjectPageError,
  selectedProjectScorePerSubmissionLoading,
  updateActiveStudentHoursPerModule,
  updatePageStatus,
} from '../../app/selectedProjectSlice';

import { useEffect } from 'react';
import styled from 'styled-components';
import { StyledGrid } from '../../components/Projects/sharedStyledComponents';
import ActivityByEvent from '../../components/Students/ActivityByEvent';
import HoursPerModule from '../../components/Students/HoursPerModule';
import ScorePerSubmission from '../../components/Students/ScorePerSubmission';
import StudentsNavBar from '../../components/Students/StudentsNavBar';
import Theme from '../../sharedStyles/Theme';
import { LoadingOverlay } from '../../svgs/loading-overlay';
import Error from '../Error';

const SelectedProject = () => {
  const reduxDispatch = useAppDispatch();
  const project = useAppSelector(navbarActiveProject);
  const studentValue = useAppSelector(navbarActiveStudent).value;
  const courseValue = useAppSelector(navbarActiveCourse).value;
  const pageError = useAppSelector(selectedProjectPageError);
  const primers = useAppSelector(navbarPrimers);
  const componentError = useAppSelector(navbarComponentError);
  const hoursPerModuleLoading = useAppSelector(
    selectedProjectHoursPerModuleLoading,
  );
  const activityByEventLoading = useAppSelector(
    selectedProjectActivityByEventLoading,
  );
  const scorePerSubmissionLoading = useAppSelector(
    selectedProjectScorePerSubmissionLoading,
  );

  useEffect(() => {
    reduxDispatch(clearActiveStudent());
    reduxDispatch(
      getStudentDataForSelectedProject(courseValue, project, studentValue),
    );
    return () => {
      reduxDispatch(updatePageStatus(false));
    };
  }, [project, reduxDispatch, studentValue, courseValue, primers]);

  useEffect(() => {
    reduxDispatch(getClassHoursPerModule(courseValue, project, primers));
  }, [project, reduxDispatch, courseValue, primers]);

  useEffect(() => {
    reduxDispatch(updateActiveStudentHoursPerModule(studentValue));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentValue]);

  return courseValue === '404' || componentError ? (
    <Error errorType={404} />
  ) : pageError ? (
    <Error errorType={500} errorMessage={pageError} />
  ) : (
    <Theme>
      <StudentsNavBar />
      <StyledSelectedProject>
        <HoursPerModule gridClass={'one'} />
        <LoadingOverlay view={hoursPerModuleLoading} gridClass={'one'} />
        <ActivityByEvent gridClass={'two'} />
        <LoadingOverlay view={activityByEventLoading} gridClass={'two'} />
        <ScorePerSubmission gridClass={'three'} />
        <LoadingOverlay view={scorePerSubmissionLoading} gridClass={'three'} />
      </StyledSelectedProject>
    </Theme>
  );
};

export default SelectedProject;

const StyledSelectedProject = styled(StyledGrid)`
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'one two two' 'one three three';
`;
