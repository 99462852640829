import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AllProjects from "./views/Students/AllProjects";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import React, { useEffect, useState } from "react";
import SelectedProject from "./views/Students/SelectedProject";
import Snapshot from "./views/Projects/Snapshot";
import Theme from "./sharedStyles/Theme";
import Trend from "./views/Projects/Trend";
import Quizzes from "./views/CheatChecking/Quizzes";
import Comparsions from "./views/CheatChecking/Comparisons";
import SingleProject from './views/Classic/SingleProject';
import AllProjectsClassic from './views/Classic/AllProjectsClassic';
import styled from "styled-components";
import { useAppSelector } from "./app/hooks";
import {
  navbarActiveCourse,
} from "./app/navbarSlice";

const { REACT_APP_FRONTEND_URI, REACT_APP_BACKEND_URI } = process.env;

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}


function App() {
  const cookieValue = getStorageValue("jwt", "baljit");
  const headers = new Headers();
  headers.append("x-access-token", cookieValue);
  
  // CheatChecking States Chekcing
  const [showQuiz, setShowQuiz] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // storing input name
    let courseSlugFromURL = window.location.pathname.split('/')[1];
    if (
      window.location.pathname.split('/').includes('auth') &&
      window.location.pathname.split('/').length === 4
    ) {
       courseSlugFromURL = window.location.pathname.split('/')[2];
      const cookieValue = window.location.pathname.split('/')[3];
      localStorage.setItem('jwt', JSON.stringify(cookieValue));
      window.location.href = `${REACT_APP_FRONTEND_URI}/${courseSlugFromURL}/`;
    } else if (
      window.location.pathname.split('/').includes('auth') &&
      window.location.pathname.split('/').length === 5
    ) {
       courseSlugFromURL = window.location.pathname.split('/')[2];
      const projectSlugFromURL = window.location.pathname.split('/')[3];
      const cookieValue = window.location.pathname.split('/')[4];
      localStorage.setItem('jwt', JSON.stringify(cookieValue));
      window.location.href = `${REACT_APP_FRONTEND_URI}/${courseSlugFromURL}/${projectSlugFromURL}`;
      
    }

    console.log(`App.tsx ${courseSlugFromURL}`)    
    if (courseSlugFromURL) {
      const fetchData = async () => {
        console.log("Cheatchekcing enable checker is called")
              try {
                const response = await fetch(`${REACT_APP_BACKEND_URI}/api/${courseSlugFromURL}/config/cheatchecking/`, {
                  method: "GET",
                  headers: headers,
                }); // replace with your actual API endpoint
                if (response.ok) {
                  const data = await response.json();
                  setShowQuiz(data); // assuming the API returns a boolean
                } else {
                  console.error("Error fetching quiz status:", response.statusText);
                  setShowQuiz(false);
                }
              } catch (error) {
                console.error("Error fetching quiz status:", error);
                setShowQuiz(false);
              } finally {
                setLoading(false);
              }
            }
      fetchData();
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Theme>
        <StyledLayout>
          <NavBar />
          <StyledComponent>
            <Switch>
              {<Route path="/auth/:course/:token" component={Snapshot} />}
              {
                <Route
                  path="/auth/:course/:project/:token"
                  component={Snapshot}
                />
              }
              {
                <Route
                  path="/:course/:project/projects/snapshot"
                  component={Snapshot}
                />
              }
              {<Route path="/:course/projects/snapshot" component={Snapshot} />}
              {<Route path="/:course/:project/projects/trend" component={Trend} />}
              {<Route path="/:course/:project/students/selected-project" component={SelectedProject} />}
              {<Route path="/:course/students/all-projects" component={AllProjects} />}
              {<Route path="/:course/classic/classic-project" component={SingleProject} />}
              {<Route path="/:course/classic/classic-all-projects" component={AllProjectsClassic} />}
              {showQuiz && <Route path="/:course/cheatchecking/quizzes" component={Quizzes}/>} 
              {showQuiz && <Route path="/:course/cheatchecking/comparisons" component={Comparsions}/>}
              
              {<Redirect from="/:course" to="/:course/projects/snapshot" />}
              <Redirect from="*" to="/404" />
            </Switch>
          </StyledComponent>
          <Footer />
        </StyledLayout>
      </Theme>
    </Router>
  );
}

export default App;

const StyledLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  color: ${(props) => props.theme.colors.secondaryDark};
`;

const StyledComponent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  background-image: url('/images/background.svg');
  background-repeat: repeat-x;
  background-position: 0% 100%;
  padding: 0 2%;
`;
