import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
  StyledNoDataResponse,
} from '../../components/Projects/sharedStyledComponents';
import {
  allProjectsActivityPerActiveStudentPerProject,
  allProjectsActivityPerStudentPerProject,
  allProjectsActivityPerStudentPerProjectLoading,
  allProjectsSelectedGraphKey,
} from '../../app/allProjectsSlice';

import Plot from 'react-plotly.js';
import React from 'react';
import StyledGraphKeySelect from '../Projects/Trend/StyledGraphKeySelect';
import { navbarProjects } from '../../app/navbarSlice';
import { useAppSelector } from '../../app/hooks';

const ActivityPerStudentPerDay = (props: { gridClass: string }) => {
  const selectedBoxplot = useAppSelector(allProjectsSelectedGraphKey)[
    'selectedBoxplot'
  ];
  const boxplotData = useAppSelector(allProjectsActivityPerStudentPerProject)[
    selectedBoxplot.value
  ];
  const projects = useAppSelector(navbarProjects);
  const studentScatterData = useAppSelector(
    allProjectsActivityPerActiveStudentPerProject,
  )[selectedBoxplot.value];
  const activityPerStudentPerProjectLoading = useAppSelector(
    allProjectsActivityPerStudentPerProjectLoading,
  );

  const graphKeyOptions = [
    { value: 'nSubmissions', label: '# of Submissions' },
    { value: 'nHours', label: '# of Hours Spent' },
    { value: 'score', label: 'Grade Distribution' },
  ];

  const modules = Array.from(
    new Set(projects.map((s: { label: any }) => s.label)),
  );

  const classData: any[] = modules.map((module) => {
    const subset = boxplotData?.filter(
      (s: { module_name: string }) => s.module_name === module,
    );

    return {
      type: 'box',
      boxpoints: 'outliers',
      jitter: 0.3,
      boxmean: true,
      line: {
        width: 2,
        color: 'rgba(19, 44, 62, 0.4)',
      },
      marker: {
        color: 'rgba(19, 44, 62, 0.4)',
        size: 10,
      },
      whiskerwidth: 1,
      name: module,
      text: subset?.length
        ? subset.map(
            (s: { nSubmissions: number; nHours: number; score: number }) => {
              switch (selectedBoxplot.value) {
                case 'nSubmissions':
                  return s.nSubmissions;
                case 'nHours':
                  return s.nHours;
                case 'score':
                  return s.score;
              }
              return 0;
            },
          )
        : 0,
      hovertemplate: `%{text} <extra></extra>`,
      y: subset?.length
        ? subset.map(
            (s: { nSubmissions: number; nHours: number; score: number }) => {
              switch (selectedBoxplot.value) {
                case 'nSubmissions':
                  return s.nSubmissions;
                case 'nHours':
                  return s.nHours;
                case 'score':
                  return s.score;
              }
              return 0;
            },
          )
        : [0],
    };
  });

  const studentData: any[] = modules.map((module) => {
    const subset = studentScatterData?.filter(
      (s: { module_name: string }) => s.module_name === module,
    );
    return {
      type: 'scatter',
      mode: 'markers+text',
      x: [module],
      y: subset?.length
        ? subset.map(
            (s: { nSubmissions: number; nHours: number; score: number }) => {
              switch (selectedBoxplot.value) {
                case 'nSubmissions':
                  return s.nSubmissions || 0;
                case 'nHours':
                  return s.nHours || 0;
                case 'score':
                  return s.score || 0;
              }
              return 0;
            },
          )
        : [0],
      hoverinfo: 'skip',
      marker: {
        color: 'red',
        size: 58 - 3.4 * classData.length,
        symbol: 'line-ew',
        line: {
          color: '#CD2C8A',
          width: 5,
        },
      },
      text: subset?.length
        ? subset.map(
            (s: { nSubmissions: number; nHours: number; score: number }) => {
              switch (selectedBoxplot.value) {
                case 'nSubmissions':
                  return ` ${s.nSubmissions}`;
                case 'nHours':
                  return ` ${s.nHours}`;
                case 'score':
                  return ` ${s.score}`;
              }
              return ' 0';
            },
          )
        : ' 0',
      textfont: {
        size: 15,
      },
      textposition: 'middle right',
    };
  });

  const data = classData.concat(studentData);

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph width={'full'}>
        <StyledGraphHeader>
          <StyledGraphTitle>Activity Per Student Per Project</StyledGraphTitle>
          <StyledGraphKeySelect
            keyOptions={graphKeyOptions}
            graphType={'selectedBoxplot'}
            page={'allProjects'}
          />
        </StyledGraphHeader>
        {boxplotData && boxplotData[0] ? (
          <Plot
            data={data}
            layout={{
              font: {
                family: 'Fira Sans, sans-serif',
                size: 12,
                color: 'rgba(19, 44, 62, 0.8)',
              },
              hovermode: 'closest',
              autosize: true,
              showlegend: false,
              margin: { l: 70, r: 10, b: 150, t: 10 },
              xaxis: { automargin: true },
              yaxis: {
                title: selectedBoxplot.label,
                automargin: true,
                rangemode: 'nonnegative',
              },
            }}
            style={{ width: '100%', height: '88%' }}
            useResizeHandler
            config={{
              modeBarButtonsToRemove: [
                'lasso2d',
                'select2d',
                'sendDataToCloud',
                'autoScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
            }}
          />
        ) : (
          !activityPerStudentPerProjectLoading && (
            <StyledNoDataResponse>
              There is no class activity yet for the selected option. <br />{' '}
              Select another activity from the dropdown to view activity per
              student per project.
            </StyledNoDataResponse>
          )
        )}
      </StyledGraph>
    </StyledGraphContainer>
  );
};

export default ActivityPerStudentPerDay;
