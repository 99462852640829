import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import React, { SyntheticEvent, useState } from 'react';
import {
  StyledGraph,
  StyledGraphActiveData,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphRightColumn,
  StyledGraphTitle,
  StyledNoDataResponse,
} from '../sharedStyledComponents';
import {
  trendActiveStudentsPerDayLoading,
  trendSelectedGraphKey,
} from '../../../app/trendSlice';

import Student from '../Student';
import StyledGraphKeySelect from './StyledGraphKeySelect';
import { navbarStudents } from '../../../app/navbarSlice';
import { useAppSelector } from '../../../app/hooks';

const NumberOfActiveStudents = (props: { gridClass: string }) => {
  const selectedActivityValue = useAppSelector(trendSelectedGraphKey)[
    'selectedActivity'
  ].value;
  const classActivity = useAppSelector(
    (state) => state.trend.activeStudentsPerDay[selectedActivityValue],
  );
  const activeStudentsPerDayLoading = useAppSelector(
    trendActiveStudentsPerDayLoading,
  );
  const numberOfStudents = useAppSelector(navbarStudents).length;

  const [activeDate, setActiveDate] = useState(0);

  const handleBarClick = (data: any, index: any) => {
    setActiveDate(index);
  };

  const [scrollData, setScrollData] = useState(1);

  const graphKeyOptions = [
    { value: 'navigation', label: 'Navigation' },
    { value: 'heartbeat', label: 'Heartbeat' },
    { value: 'submission', label: 'Submission' },
    { value: 'reflection', label: 'Reflection' },
    { value: 'assessme', label: 'AssessMe' },
  ];

  const plotFontSize = '.75rem';

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph>
        <StyledGraphHeader>
          <StyledGraphTitle>Active Students Per Day</StyledGraphTitle>
          <StyledGraphKeySelect
            keyOptions={graphKeyOptions}
            graphType={'selectedActivity'}
            page={'trend'}
          />
        </StyledGraphHeader>
        {classActivity[0] ? (
          <ResponsiveContainer height="85%">
            <BarChart data={classActivity} margin={{ top: 15, bottom: 5 }}>
              <CartesianGrid strokeDasharray="10 10" />
              <XAxis
                label={{
                  fontSize: plotFontSize,
                  fill: `rgba(19, 44, 62, 0.7)`,
                  value: 'Date',
                  position: 'outside',
                  dy: 14,
                }}
                dataKey="shortened_date"
                tick={{ fontSize: plotFontSize, fill: `rgba(19, 44, 62, 0.7)` }}
              />
              <YAxis
                label={{
                  fontSize: plotFontSize,
                  fill: `rgba(19, 44, 62, 0.7)`,
                  value: '# of Active Students',
                  position: 'outside',
                  angle: '270',
                  dx: -10,
                }}
                tick={{
                  fontSize: plotFontSize,
                  fill: `rgba(19, 44, 62, 0.7)`,
                }}
                domain={[0, numberOfStudents]}
              />
              <Bar dataKey="nStudents" fill="#DCDFE2" onClick={handleBarClick}>
                <LabelList
                  dataKey="nStudents"
                  position="top"
                  style={{ fontSize: '.8rem', fill: `rgba(19, 44, 62, 0.7)` }}
                />
                {classActivity &&
                  classActivity.map((entry: any, index: number) => (
                    <Cell
                      cursor="pointer"
                      fill={index === activeDate ? '#204E5D' : '#DCDFE2'}
                      key={`cell-${index}`}
                    />
                  ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          !activeStudentsPerDayLoading && (
            <StyledNoDataResponse>
              There is no class activity on this project yet for the selected
              activity. <br /> Select another activity from the dropdown to view
              active students.
            </StyledNoDataResponse>
          )
        )}
      </StyledGraph>

      {classActivity[activeDate] && (
        <StyledGraphRightColumn>
          Active on {classActivity[activeDate].full_date}
          <StyledGraphActiveData
            onScroll={(e: SyntheticEvent) => {
              e.preventDefault();
              const tgt = e.target as unknown as HTMLDivElement;

              if (tgt.scrollTop + 100 >= tgt.scrollHeight - tgt.clientHeight) {
                setScrollData(
                  Math.min(
                    scrollData + 1,
                    Math.ceil(classActivity[activeDate].students.length / 15),
                  ),
                );
              }
            }}
          >
            {classActivity[activeDate].students &&
              classActivity[activeDate].students
                .slice(0, scrollData * 15)
                .map((student: any, index: React.Key) => (
                  <Student key={index} student={student} />
                ))}
          </StyledGraphActiveData>
        </StyledGraphRightColumn>
      )}
    </StyledGraphContainer>
  );
};

export default NumberOfActiveStudents;
