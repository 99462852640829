import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
} from '../Projects/sharedStyledComponents';

import React from 'react';
import Timeline from './Timeline';
import { useAppSelector } from '../../app/hooks';

const ProjectSubmissionsTimeline = (props: { gridClass: string }) => {
  const projectList = useAppSelector((state) => state.navbar.projects);

  return (
    <StyledGraphContainer gridClass={props.gridClass} maxHeight={45}>
      <StyledGraph width="full">
        <StyledGraphHeader>
          <StyledGraphTitle>
            Earliest and Latest Project Submissions Timeline
          </StyledGraphTitle>
        </StyledGraphHeader>
        {projectList.map((project: any, index: any) => (
          <Timeline project={project} key={index} />
        ))}
      </StyledGraph>
    </StyledGraphContainer>
  );
};

export default ProjectSubmissionsTimeline;
