import { ThemeProvider } from 'styled-components';

export const theme = {
  colors: {
    brandDark: '#2F7F6F',
    brandLight: '#67D7B6',
    secondaryDark: '#132C3E',
    white: '#FFFFFF',
    textBackground: '#E8F9F4',
  },
  responsive: {
    md: '1000px',
    sm: '800px',
  },
  fontSizes: {
    plotText: '.75rem',
  },
};

const Theme: React.FC<any> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
