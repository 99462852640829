import {TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { StyledTable } from "../../../sharedStyles/sharedStyledComponents";
import {activeCaseMetaData, CaseMetaDataStudentInfo} from "../../../app/navbarQuizCheatCheckingSlice";
import {useAppSelector} from "../../../app/hooks";
import {studentInfo} from "../../../app/quizCheatCheckingSlice";

export const StudentInfoTableWithProps = (props: {studentInfos: any, studentIDs:any}) => {
    const studentInfoRedux = props.studentInfos
    // console.log(`[StudentInfoTable] ${JSON.stringify(props.studentInfos)}`)
    const StudentIDs = props.studentIDs

    // StudentIDs.map((studentID: any) => {
    //     console.log(`[StudentIDs]: ${studentID} : ${JSON.stringify(studentInfoRedux[studentID].usedGraceDayNumber)}`)
    // })
        
    


    return (
        <TableContainer style={{
            padding: '1rem 1rem 1rem 1rem',
            width: '96%'
        }}>
            <StyledTable key='StudentInfoTable'>
                <TableHead key='StudentInfoTableHead'>
                    <TableRow key='StudentInfoTableHeadRow'>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>First Name</StyledTableCell>
                        <StyledTableCell>Last Name</StyledTableCell>
                        <StyledTableCell>College</StyledTableCell>
                        <StyledTableCell>Department</StyledTableCell>
                        <StyledTableCell>Course</StyledTableCell>
                        <StyledTableCell>Section</StyledTableCell>
                        <StyledTableCell>Team</StyledTableCell>
                        <StyledTableCell>Time Spent</StyledTableCell>
                        <StyledTableCell>Score</StyledTableCell>
                        <StyledTableCell>Used GraceDay</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody key='StudentInfoTableBody'>
                    {StudentIDs.map((studentID: any) => (
                        <TableRow key={studentID}>
                            <StyledTableCell>{studentID}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].firstName ? studentInfoRedux[studentID].firstName : "no data"}</StyledTableCell> 
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].lastName ? studentInfoRedux[studentID].lastName : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].college ? studentInfoRedux[studentID].college : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].department ? studentInfoRedux[studentID].department : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].course ? studentInfoRedux[studentID].course : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].campus ? studentInfoRedux[studentID].campus : "no data"}</StyledTableCell>
                             <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].team ? studentInfoRedux[studentID].team : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].quizTimeDuration ? studentInfoRedux[studentID].quizTimeDuration : "no data"} Minutes</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].quizScore ? studentInfoRedux[studentID].quizScore : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[studentID] && studentInfoRedux[studentID].usedGraceDayNumber !== undefined && studentInfoRedux[studentID].usedGraceDayNumber !== null ? (Number(studentInfoRedux[studentID].usedGraceDayNumber) === -1 ? "no data" : (studentInfoRedux[studentID].usedGraceDayNumber + " Days")) : "no data"}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

const StyledTableCell = withStyles((theme) => ({
    root: {
        fontSize: 14,
        fontWeight: 600,
        color: "#132C3E",
        fontFamily: "Fira Sans",
    },
    head: {
        backgroundColor: "#E8F9F4",
        padding: "1rem 1rem",
    },
    body: {
        padding: ".6rem 1rem",
    },
}))(TableCell);
