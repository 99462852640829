import {TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {StyledTable} from "../../../sharedStyles/sharedStyledComponents";
import {useAppSelector} from "../../../app/hooks";
import {pageViewAnalysis} from "../../../app/quizCheatCheckingSlice";

export const PageViewAnalysisTable = () => {
    const pageViewAnalysisRedux = useAppSelector(pageViewAnalysis);
    return (
        <TableContainer style={{
            padding: '1rem 1rem 1rem 1rem',
            width: '96%'
        }}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Overlap Numbers</StyledTableCell>
                        <StyledTableCell>Overlap Time (Sec)</StyledTableCell>
                        <StyledTableCell>Synchronous Steps</StyledTableCell>
                        <StyledTableCell>Overlapping Percentage</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>{pageViewAnalysisRedux.overlapPageNumber === -1 ? "/" : pageViewAnalysisRedux.overlapPageNumber}</StyledTableCell>
                        <StyledTableCell>{pageViewAnalysisRedux.overlapTimeDuration === -1 ? "/" : pageViewAnalysisRedux.overlapTimeDuration}</StyledTableCell>
                        <StyledTableCell>{pageViewAnalysisRedux.syncPageNumber === -1 ? "/" : pageViewAnalysisRedux.syncPageNumber}</StyledTableCell>
                        <StyledTableCell>{pageViewAnalysisRedux.overlapPercentage === -1 ? "/" : pageViewAnalysisRedux.overlapPercentage + "%"}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

const StyledTableCell = withStyles((theme) => ({
    root: {
        fontSize: 14,
        fontWeight: 600,
        color: "#132C3E",
        fontFamily: "Fira Sans",
    },
    head: {
        backgroundColor: "#E8F9F4",
        padding: "1rem 1rem",
    },
    body: {
        padding: ".6rem 1rem",
    },
}))(TableCell);
