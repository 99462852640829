import React, { useEffect } from 'react';
import { StyledNavItem, StyledNavItems } from '../NavBar';
import { navbarActiveCourse, navbarActiveProject } from '../../app/navbarSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Link } from 'react-router-dom';
import NavBarSelect from '../NavBarSelect';
import Theme from '../../sharedStyles/Theme';
import { navLinkProps } from '../NavBar';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const StudentsNavBar = (props: any) => {
  const reduxDispatch = useAppDispatch();

  const courseValue = useAppSelector(navbarActiveCourse).value;
  const projectValue = useAppSelector(navbarActiveProject).value;
  const location = useLocation();

  const studentsNavButtons: navLinkProps[] = [
    {
      label: 'Selected Project',
      path: 'selected-project',
      redirect: `/${courseValue}/${projectValue}/students/selected-project`,
    },
    {
      label: 'All Projects',
      path: 'all-projects',
      redirect: `/${courseValue}/students/all-projects`,
    },
  ];

  useEffect(() => {
    // reduxDispatch(getStudentDataForSelectedProject());
  }, [reduxDispatch]);

  return (
    <Theme>
      <>
        <StyledProjectsHeader>
          <StyledProjectsNavBar>
            {studentsNavButtons.map((button: navLinkProps) => (
              <StyledProjectItem
                key={button.path}
                active={location.pathname.includes(button.path)}
              >
                <Link
                  to={{
                    pathname: button.redirect,
                  }}
                >
                  {button.label}
                </Link>
              </StyledProjectItem>
            ))}
          </StyledProjectsNavBar>
          <StyledSelects>
            <NavBarSelect type="Project" hide={props.hideProjectsSelect} />
            <NavBarSelect type="Student" />
          </StyledSelects>
        </StyledProjectsHeader>
      </>
    </Theme>
  );
};

export default StudentsNavBar;

const StyledProjectsHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 0.1rem solid rgba(159, 160, 162, 0.3);
  margin: 1rem 0;

  @media (max-width: ${(props) => props.theme.responsive.md}) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 2fr;
  }
`;

const StyledProjectsNavBar = styled(StyledNavItems)`
  justify-content: left;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;

  @media (max-width: ${(props) => props.theme.responsive.md}) {
    display: flex;
  }
`;

const StyledProjectItem = styled(StyledNavItem)`
  margin-left: 0;
  padding-bottom: 0.5rem;
`;

const StyledSelects = styled.div`
  display: flex;
  margin-left: auto;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    padding: 0.25rem 0;
    flex-direction: column;
    margin-left: revert;
  }
`;
