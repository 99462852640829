import * as _ from 'lodash';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './store';

export interface SnapshotState {
  previousProjects: Array<SelectOption>;
  students: Array<SubmitterOption>;
  pageStatus: boolean;
  snapshotProject: any;
  nonSubmitters: Array<SubmitterOption>;
  pageError: string;
}

const initialState: SnapshotState = {
  previousProjects: [],
  students: [
    {
      email: '',
      full_name: '',
      projects: [],
    },
  ],
  pageStatus: false,
  snapshotProject: '',
  nonSubmitters: [],
  pageError: '',
};

interface SelectOption {
  value: string;
  label: string;
}

interface SubmitterOption {
  email: string;
  full_name: string;
  hoursspent?: number;
  module_score?: number;
  nSubmissions?: number;
  projects: Array<any>;
}

export const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState,
  reducers: {
    setSnapshotProject: (state, action: PayloadAction<any>) => {
      state.snapshotProject = action.payload;
    },
    updateStudents: (
      state,
      action: PayloadAction<{ data: any; reset: boolean }>,
    ) => {
      const students = action.payload.reset ? [] : state.students;

      const updatedStudents = Object.values(
        _.merge(
          _.keyBy(students, 'email'),
          _.keyBy(action.payload.data, 'email'),
        ),
      );

      state.students = updatedStudents;
      state.nonSubmitters = updatedStudents.filter(
        (obj) => !Object.keys(obj).includes('nSubmissions'),
      );
    },
    updateNonSubmittersWithPreviousProjectScores: (
      state,
      action: PayloadAction<any>,
    ) => {
      const studentsWithScores = action.payload.scores;
      const project = action.payload.project;

      state.nonSubmitters.map((nonSubmitter) => {
        const score =
          studentsWithScores.find(
            (x: { email: string }) => x.email === nonSubmitter.email,
          )?.module_score || 0;

        const p = { project: project, score: score };

        if (nonSubmitter.projects) {
          nonSubmitter.projects[action.payload.index] = p;
        } else {
          nonSubmitter.projects = [];
          nonSubmitter.projects[action.payload.index] = p;
        }

        return nonSubmitter;
      });
    },
    updatePageStatus: (state, action: PayloadAction<boolean>) => {
      state.pageStatus = action.payload;
    },
    updatePreviousProjects: (state, action: PayloadAction<any>) => {
      state.previousProjects = action.payload;
    },
    updatePageError: (state, action: PayloadAction<any>) => {
      state.pageError = action.payload;
    },
  },
});

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

// ****************
// APIs
// ****************

const { REACT_APP_BACKEND_URI } = process.env;

export const getHoursScoreSubmissionsPerStudent =
  (courseValue: string, project: any, projectList: any): AppThunk =>
  (dispatch) => {
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    const previousProjects = projectList.filter(function (p: {
      date_to_submit: number;
    }) {
      return p.date_to_submit < project.date_to_submit;
    });

    if (courseValue && project.value) {
      Promise.all([
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/NHoursPerStudent/${project.value}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/NSubmissionsPerStudent/${project.value}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/ScorePerStudent/${project.value}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
      ])
        .then((responses) => {
          return Promise.all(
            responses.map(async function (response) {
              if (response.ok) return response.json();
              else {
                const errorText = await response.text();
                throw new Error(`${errorText} for ${response.url}`);
              }
            }),
          );
        })
        .then((jsonData) => {
          return jsonData.map(function (json, index) {
            const data = json.map(function (obj: any) {
              obj['value'] = obj['email'];
              obj['label'] = obj['full_name'] || obj['email'].split('@')[0];
              return obj;
            });
            return dispatch(
              updateStudents({ data: data, reset: index === 0 ? true : false }),
            );
          });
        })
        .then(() => {
          dispatch(updatePreviousProjects(previousProjects));
          return previousProjects.map(
            (p: { value: any; endDate: any }, i: number) => {
              return fetch(
                `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/scoreperstudent/${p.value}`,
                { method: 'GET', headers: headers },
              )
                .then(async (response) => {
                  if (response.ok) return response.json();
                  else {
                    const errorText = await response.text();
                    throw new Error(`${errorText} for ${response.url}`);
                  }
                })
                .then((myJson) => {
                  dispatch(
                    updateNonSubmittersWithPreviousProjectScores({
                      scores: myJson,
                      project: p,
                      index: i,
                    }),
                  );
                })
                .catch((error) => {
                  dispatch(updatePageError(error.toString()));
                  dispatch(updatePageStatus(true));
                });
            },
          );
        })
        .then(() => {
          dispatch(setSnapshotProject(project));
          dispatch(updatePageStatus(true));
        })
        .catch((error) => {
          dispatch(updatePageError(error.toString()));
          dispatch(updatePageStatus(true));
        });
    }
  };

// **************************************************************
// Exports
// **************************************************************
export const {
  setSnapshotProject,
  updateStudents,
  updateNonSubmittersWithPreviousProjectScores,
  updatePageStatus,
  updatePreviousProjects,
  updatePageError,
} = snapshotSlice.actions;

export const snapshotPageStatus = (state: RootState) =>
  state.snapshot.pageStatus;
export const snapshotSnapshotProject = (state: RootState) =>
  state.snapshot.snapshotProject;
export const snapshotStudents = (state: RootState) => state.snapshot.students;
export const snapshotPreviousProjects = (state: RootState) =>
  state.snapshot.previousProjects;
export const snapshotNonSubmitters = (state: RootState) =>
  state.snapshot.nonSubmitters;
export const snapshotMaxScore = (state: RootState) =>
  state.snapshot.snapshotProject.max_score || 90;
export const snapshotPageError = (state: RootState) => state.snapshot.pageError;

export default snapshotSlice.reducer;
