export const ButtonExpand = ({ fillOpacity = '0.1' }) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.5"
      cy="13.5"
      r="13.5"
      fill="#132C3E"
      fillOpacity={fillOpacity}
      id="circle"
    />
    <path
      d="M9 12L13.5 16L18 12"
      stroke="#132C3E"
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
