import React, { useEffect } from 'react';
import { StyledNavItem, StyledNavItems, navLinkProps } from '../NavBar';
import {
  getHoursScoreSubmissionsPerStudent,
  snapshotSnapshotProject,
  updatePageStatus,
} from '../../app/snapshotSlice';
import { getTrendsPerProject, resetTrendLoadings } from '../../app/trendSlice';
import {
  navbarActiveCourse,
  navbarActiveProject,
  navbarProjects,
} from '../../app/navbarSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Link } from 'react-router-dom';
import NavBarSelect from '../NavBarSelect';
import Theme from '../../sharedStyles/Theme';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const ProjectsNavBar = () => {
  const reduxDispatch = useAppDispatch();

  const location = useLocation();
  const courseValue = useAppSelector(navbarActiveCourse).value;
  const snapshotProject = useAppSelector(snapshotSnapshotProject);
  const project = useAppSelector(navbarActiveProject);
  const projectList = useAppSelector(navbarProjects);

  useEffect(() => {
    if (snapshotProject === project) {
      reduxDispatch(updatePageStatus(true));
    } else if (courseValue) {
      reduxDispatch(resetTrendLoadings());
      reduxDispatch(
        getHoursScoreSubmissionsPerStudent(courseValue, project, projectList),
      );
      reduxDispatch(getTrendsPerProject(courseValue, project));
    }
    return () => {
      reduxDispatch(updatePageStatus(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseValue, project]);

  const projectNavButtons: navLinkProps[] = [
    {
      label: 'Snapshot',
      path: 'snapshot',
      redirect: `/${courseValue}/${project.value}/projects/snapshot`,
    },
    {
      label: 'Trend',
      path: 'trend',
      redirect: `/${courseValue}/${project.value}/projects/trend`,
    },
  ];
  return (
    <Theme>
      <>
        <StyledProjectsHeader>
          <StyledProjectsNavBar>
            {projectNavButtons.map((button: navLinkProps) => (
              <StyledProjectItem
                key={button.path}
                active={location.pathname.includes(button.path)}
              >
                <Link
                  to={{
                    pathname: button.redirect,
                  }}
                >
                  {button.label}
                </Link>
              </StyledProjectItem>
            ))}
          </StyledProjectsNavBar>
          <NavBarSelect type="Project" />
        </StyledProjectsHeader>
      </>
    </Theme>
  );
};

export default ProjectsNavBar;

const StyledProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid rgba(159, 160, 162, 0.3);
  margin: 1rem 0;
`;

const StyledProjectsNavBar = styled(StyledNavItems)`
  justify-content: left;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  @media (max-width: ${(props) => props.theme.responsive.md}) {
    display: flex;
  }
`;

const StyledProjectItem = styled(StyledNavItem)`
  margin-left: 0;
  padding-bottom: 0.5rem;
`;
