import {
    ContainerHeader,
    ContainerTitle,
    GriddedContainer,
} from "../../sharedStyles/sharedStyledComponents";
import {
    caseCheckStatusMap,
    isCaseFixed,
    navbarActiveCase,
    navbarActiveCaseFilter,
    navbarActiveCaseOrder,
    navbarActiveCaseType,
    navbarActiveQuiz,
    navbarActiveScope,
    navbarCaseFilters,
    navbarCaseOrders,
    navbarCaseTypes,
    navbarCases,
    navbarQuizzes,
    navbarScopes,
    updateActiveCaseByButton,
    updateActiveCaseBySelect,
    updateActiveCaseFilter,
    updateActiveCaseOrder,
    updateActiveCaseType,
    updateActiveQuiz,
    updateActiveScope,
    updateCaseCheckStatusMap,
    updateIsCaseFixed
} from "../../app/navbarQuizCheatCheckingSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import React from "react";
import {Selector} from "../Selector";
import {TopSuspiciousFilterSetting} from "./Quizzes/TopSuspiciousFilterSetting";
import {navbarActiveCourse} from "../../app/navbarSlice";
import {theme} from "../../sharedStyles/Theme";

export const CheatCheckingSelectors = (props: any) => {
    const reduxDispatch = useAppDispatch();
    const isCaseFixedRedux = useAppSelector(isCaseFixed);
    const navbarActiveCaseRedux = useAppSelector(navbarActiveCase);
    const caseCheckStatusMapRedux = useAppSelector(caseCheckStatusMap);
    const courseSlug = useAppSelector(navbarActiveCourse).value;
    const navbarActiveScopeRedux =  useAppSelector(navbarActiveScope);
    const quizSlug = useAppSelector(navbarActiveQuiz).value;
    const navbarActiveCaseTypeRedux = useAppSelector(navbarActiveCaseType);
    const navbarActiveCaseFilterRedux = useAppSelector(navbarActiveCaseFilter);

    const handleQuizSelect = (event: any) => {
        // console.log(`update Active Quiz ${event}`)
        reduxDispatch(updateActiveQuiz(event));
    };
    const handleCaseSelect = (event: any) => {
        reduxDispatch(updateActiveCaseBySelect(event));
    };

    const handleNextCaseButton = () => {
        reduxDispatch(updateActiveCaseByButton(true));
    }

    const handlePrevCaseButton = () => {
        reduxDispatch(updateActiveCaseByButton(false));
    }

    const handleScopeSelect = (event: any) => {
        reduxDispatch(updateActiveScope(event));
    }

    const handleCaseTypeSelect = (event: any) => {
        reduxDispatch(updateActiveCaseType(event));
    }

    const handleCaseFilterSelect = (event: any) => {
        reduxDispatch(updateActiveCaseFilter(event));
    };

    const handleCaseOrderSelect = (event: any) => {
        reduxDispatch(updateActiveCaseOrder(event));
    }

    const flipIsCaseFixed = () => {
        reduxDispatch(updateIsCaseFixed(!isCaseFixedRedux))
    }

    const setCaseCheckStatus = (checkStatus: string) => {
        const input = {
            caseId: navbarActiveCaseRedux.value,
            checkStatus: checkStatus
        }
        const checkStatusMap = JSON.parse(localStorage.getItem('CheckStatusMap') || '{}')
        const checkStatusMapKey = courseSlug + '_' + navbarActiveScopeRedux.value + '_' + quizSlug + '_' + navbarActiveCaseTypeRedux.value;
        const newCheckStatusMap = {...checkStatusMap};
        const caseCheckStatusMap = newCheckStatusMap[checkStatusMapKey] || {}
        caseCheckStatusMap[navbarActiveCaseRedux.value] = checkStatus;
        newCheckStatusMap[checkStatusMapKey] = caseCheckStatusMap;
        localStorage.setItem('CheckStatusMap', JSON.stringify(newCheckStatusMap));
        reduxDispatch(updateCaseCheckStatusMap(input))
    }

    const FixCaseCheckbox = () => (
        <div style={{backgroundColor: theme.colors.white, width: '100%', textAlign: 'center', height: '2rem', margin: '1% 0 1% 0', borderRadius: '4px'}}>
            
            <label style={{margin: '0', display: 'inline-block', verticalAlign: 'top', textAlign: 'center', lineHeight: '2rem', width: '30%', height: '100%', overflow: 'hidden'}}> <input style={{margin: '0 0 0 0', display: 'inline-block', verticalAlign: 'top', width: '6%', height: '100%',}} type="checkbox" checked={isCaseFixedRedux} onChange={flipIsCaseFixed}/>Fix Case </label>
        </div>
    )

    const CheckedCheckbox = () => (
        <div style={{backgroundColor: theme.colors.white, width: '49%', textAlign: 'center', display: 'inline-block',
            height: '2rem', alignItems: 'center', margin: '1% 1% 1% 0', borderRadius: '4px'}}>
            
            <label style={{margin: '0', display: 'inline-block', verticalAlign: 'top', textAlign: 'center', lineHeight: '2rem', width: '60%', height: '100%', overflow: 'hidden'}}> <input style={{margin: '0 0 0 0', display: 'inline-block', verticalAlign: 'top', width: '10%', height: '100%',}} type="checkbox" checked={caseCheckStatusMapRedux[navbarActiveCaseRedux.value] === 'Checked'} onChange={() => setCaseCheckStatus('Checked')}/>Cleared </label>
        </div>
    )

    const MarkedCheckbox = () => (
        <div style={{backgroundColor: theme.colors.white, width: '49%', textAlign: 'center', display: 'inline-block',
            height: '2rem',alignItems: 'center', margin: '1% 0 1% 1%', borderRadius: '4px'}}>
            
            <label style={{margin: '0', display: 'inline-block', verticalAlign: 'top', textAlign: 'center', lineHeight: '2rem', width: '60%', height: '100%', overflow: 'hidden'}}> <input style={{margin: '0 0 0 0', display: 'inline-block', verticalAlign: 'top', width: '10%', height: '100%',}} type="checkbox" checked={caseCheckStatusMapRedux[navbarActiveCaseRedux.value] === 'Marked'} onChange={() => setCaseCheckStatus('Marked')}/>Marked </label>
        </div>
    )

    return (
        <GriddedContainer gridClass={props.gridClass} height={props.height} backgroundColor={props.backgroundColor} hasBoxShadow={true} >
            <ContainerHeader width='100%' height='1rem'>
                <ContainerTitle>Selector Sidebar</ContainerTitle>
            </ContainerHeader>
            <Selector label="Scope" isSearchable={false} width='40%' margin='2% 2% 2% 4%' isDisabled={isCaseFixedRedux} optionsReduxSelector={navbarScopes} selectedReduxSelector={navbarActiveScope} handleSelect={handleScopeSelect} hasButton={false} />
            <Selector label="Quiz" isSearchable={false} width='40%' margin='2% 4% 2% 2%' isDisabled={false} optionsReduxSelector={navbarQuizzes} selectedReduxSelector={navbarActiveQuiz} handleSelect={handleQuizSelect} hasButton={false}/>
            <Selector label="Case Type" isSearchable={false} width='40%' margin='2% 2% 2% 4%' isDisabled={isCaseFixedRedux} optionsReduxSelector={navbarCaseTypes} selectedReduxSelector={navbarActiveCaseType} handleSelect={handleCaseTypeSelect} hasButton={false}/>
            <Selector label="Filter" isSearchable={false} width='40%' margin='2% 4% 2% 2%' isDisabled={isCaseFixedRedux} optionsReduxSelector={navbarCaseFilters} selectedReduxSelector={navbarActiveCaseFilter} handleSelect={handleCaseFilterSelect} hasButton={false}/>
            {navbarActiveCaseFilterRedux.value === 'topSuspicious' ? (<TopSuspiciousFilterSetting />) : undefined}
            <Selector label="Sort By" isSearchable={false} isDisabled={isCaseFixedRedux} optionsReduxSelector={navbarCaseOrders} selectedReduxSelector={navbarActiveCaseOrder} handleSelect={handleCaseOrderSelect} hasButton={false}/>
            <Selector label="Case" isSearchable={true} height='13rem' isDisabled={isCaseFixedRedux} optionsReduxSelector={navbarCases} selectedReduxSelector={navbarActiveCase} handleSelect={handleCaseSelect} hasButton={true} buttonLabels={["Prev Case", "Next Case"]} buttonClickHandlers={[handlePrevCaseButton,handleNextCaseButton]} otherChildren={[<CheckedCheckbox />, <MarkedCheckbox />, <FixCaseCheckbox />]}/>
        </GriddedContainer>
    )
}

