import React from 'react';
import Theme from '../sharedStyles/Theme';
import styled from 'styled-components';

const Error = (props: { errorType: number; errorMessage?: string }) => {
  let title = 'Error';
  let message = 'Please refresh or visit this page at a later time.';
  let message2 = '';
  switch (props.errorType) {
    case 404:
      title = '404 Page Not Found';
      message =
        'Please go back or return to Sail() to re-access the Dashboard.';
      break;
    case 500:
      title = '500 Internal Server Error';
      message =
        'The database for your course is currently syncing the latest data. Please check back again in 2-3 hours';
      message2 = 'Please refresh or visit this page at a later time.';
      break;
  }
  return (
    <Theme>
      <StyledError>
        <StyledErrorTitle>{title}</StyledErrorTitle>
        <StyledErrorMessage>[{message}]</StyledErrorMessage>
        <StyledErrorText>{message2}</StyledErrorText>
        <StyledErrorText>
          If you are still having trouble, you can{' '}
          <StyledA
            href="https://docs.google.com/forms/d/e/1FAIpQLSdBQaLgP8h_OBsfW45-7by6w06lrq_4OZwPmC8OsOPVUXMMOQ/viewform?usp=sf_link"
            target="​_blank"
          >
            report this issue here
          </StyledA>
          {' or contact a Sail() team member.'}
        </StyledErrorText>
      </StyledError>
    </Theme>
  );
};

export default Error;

const StyledErrorTitle = styled.div`
  font-size: 3rem;
  margin-bottom: 2rem;
`;

const StyledErrorMessage = styled.div`
  font-size: 1rem;
  margin-bottom: 3rem;
`;

const StyledErrorText = styled.div`
  font-size: 1.5rem;
  padding-top: 1rem;
`;

const StyledError = styled.div`
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20%;
`;

const StyledA = styled.a`
  &:hover {
    text-decoration: underline;
  }
  color: teal;
`;
