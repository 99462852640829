import React, { useState } from 'react';
import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
  StyledNoDataResponse,
} from '../sharedStyledComponents';
import {
  trendHoursPerStudentPerDayLoading,
  trendScorePerStudentPerDayLoading,
  trendSelectedGraphKey,
  trendSubmissionsPerStudentPerDayLoading,
} from '../../../app/trendSlice';

import Plot from 'react-plotly.js';
import StyledGraphKeySelect from './StyledGraphKeySelect';
import { navbarActiveProject } from '../../../app/navbarSlice';
import { useAppSelector } from '../../../app/hooks';

const ActivityPerStudentPerDay = (props: { gridClass: string }) => {
  const project = useAppSelector(navbarActiveProject);
  const submissionsPerStudentPerDayLoading = useAppSelector(
    trendSubmissionsPerStudentPerDayLoading,
  );
  const hoursPerStudentPerDayLoading = useAppSelector(
    trendHoursPerStudentPerDayLoading,
  );
  const scorePerStudentPerDayLoading = useAppSelector(
    trendScorePerStudentPerDayLoading,
  );

  const selectedBoxplot = useAppSelector(trendSelectedGraphKey)[
    'selectedBoxplot'
  ];
  const boxplotData = useAppSelector(
    (state) => state.trend.boxplot[selectedBoxplot.value],
  );

  const graphKeyOptions = [
    {
      value: 'nSubmissions',
      label: '# of Submissions',
      text: '# of Submissions',
    },
    { value: 'nHours', label: '# of Hours Spent', text: '# of Hours Spent' },
    { value: 'score', label: 'Grade Distribution', text: 'Score' },
  ];

  const [activeDate, setActiveDate] = useState(
    project.date_to_activate?.split('T')[0] || new Date(),
  );

  const days = Array.from(
    new Set(boxplotData.map((s: { date: any }) => s.date)),
  );

  const data: any[] = days.map((date) => {
    const subset = boxplotData.filter(
      (s: { date: unknown }) => s.date === date,
    );
    return {
      type: 'box',
      boxpoints: 'outliers',
      jitter: 0.3,
      boxmean: true,
      // hoverinfo: "text",
      line: {
        width: 2,
        color:
          activeDate === date
            ? 'rgba(103, 93, 221, 1)'
            : 'rgba(19, 44, 62, 0.4)',
      },
      marker: {
        color:
          activeDate === date
            ? 'rgba(103, 93, 221, 1)'
            : 'rgba(19, 44, 62, 0.4)',
        size: 10,
      },
      whiskerwidth: 1,
      name: date,
      text: subset.map((s: { email: string }) => s.email),
      y: subset.map(
        (s: { nSubmissions: number; nHours: number; score: number }) => {
          switch (selectedBoxplot.value) {
            case 'nSubmissions':
              return s.nSubmissions;
            case 'nHours':
              return s.nHours;
            case 'score':
              return s.score;
          }
          return '';
        },
      ),
      hovertemplate: `%{text}<br>${selectedBoxplot.text}: %{y}<br>Date: %{x}<extra></extra>`,
    };
  });

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph width={'full'}>
        <StyledGraphHeader>
          <StyledGraphTitle>Activity Per Student Per Day</StyledGraphTitle>
          <StyledGraphKeySelect
            keyOptions={graphKeyOptions}
            graphType={'selectedBoxplot'}
            page={'trend'}
          />
        </StyledGraphHeader>
        {boxplotData[0] ? (
          <Plot
            data={data}
            layout={{
              font: {
                family: 'Fira Sans, sans-serif',
                size: 12,
                color: 'rgba(19, 44, 62, .7)',
              },
              autosize: true,
              hovermode: 'closest',
              showlegend: false,
              margin: { l: 70, r: 10, b: 40, t: 10 },
              yaxis: { title: selectedBoxplot.label, rangemode: 'nonnegative' },
            }}
            style={{ width: '100%', height: '88%' }}
            useResizeHandler
            onClick={(event) => {
              setActiveDate(event.points[0].x?.toString() || '');
            }}
            config={{
              modeBarButtonsToRemove: [
                'lasso2d',
                'select2d',
                'sendDataToCloud',
                'autoScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'toggleSpikelines',
              ],
            }}
          />
        ) : (
          !submissionsPerStudentPerDayLoading &&
          !scorePerStudentPerDayLoading &&
          !hoursPerStudentPerDayLoading && (
            <StyledNoDataResponse>
              There is no class activity on this project yet for the selected
              activity. <br /> Select another activity from the dropdown to view
              activity per student.
            </StyledNoDataResponse>
          )
        )}
      </StyledGraph>
      {/* {activeDate && (
        <StyledGraphRightColumn>
          <StyledGraphActiveData>
            {formatDate(activeDate)} Statistics
            <br />
            {/* Mean: {boxplot[activeIndex].meanSubmissions.toFixed(2)} */}
      <br />
      {/* Median: {boxplot[activeIndex].medianSubmissions} */}
      <br />
      {/* Outliers: */}
      {/* {classActivity[activeIndex].students &&
            classActivity[activeIndex].students.map(
              (
                student: { email: any; name: string },
                index: React.Key | null | undefined
              ) => (
                <Student
                  key={index}
                  to={{ pathname: "/students/selected-project" }}
                  data-tip={student.email}
                  onClick={() => redirectToStudent(student)}
                >
                  <Left>
                    {student.name
                      ? shortenStudentName(student.name)
                      : shortenStudentName(student.email)}
                  </Left>
                  <Right>
                    <ExpandImg
                      src="/images/button-expand-right.svg"
                      alt={`Expand Activity for ${student.name}`}
                    />{" "}
                  </Right>
                </Student>
              )
            )}
          </StyledGraphActiveData>
        </StyledGraphRightColumn>
      )} */}
    </StyledGraphContainer>
  );
};

export default ActivityPerStudentPerDay;
