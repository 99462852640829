import styled from "styled-components";

export const LoadingLogo = (props: any) => (
  <StyledLoadingLogo
      fontSize={props.fontSize? props.fontSize: '2rem'}
  >
    <StyledSpinner
      width={props.width? props.width: '80'}
      height={props.height? props.height: '80'}
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.68658 27.0508C4.62681 25.5422 2.94514 23.5448 1.78194 21.2254C0.618741 18.906 0.00776058 16.3319 0 13.7179V13.3329C0.00755055 10.7189 0.618443 8.14469 1.78166 5.82526C2.94488 3.50583 4.62666 1.50848 6.68658 0L8.36173 2.77878C6.74619 4.02677 5.4344 5.64874 4.53099 7.51533C3.62759 9.38192 3.15759 11.4415 3.15846 13.5297C3.15932 15.618 3.63105 17.6771 4.536 19.5429C5.44095 21.4087 6.75409 23.0295 8.37067 24.276L6.68658 27.0508Z"
        fill="#58C1A8"
      />
      <path
        d="M15.0553 27.0508L13.3814 24.272C14.9969 23.0238 16.3086 21.4017 17.2121 19.535C18.1155 17.6684 18.5857 15.6088 18.5851 13.5204C18.5844 11.4321 18.1131 9.37281 17.2085 7.50672C16.3039 5.64063 14.9912 4.01936 13.375 2.77217L15.0553 0C17.1155 1.50826 18.7976 3.50553 19.961 5.82499C21.1245 8.14444 21.7355 10.7187 21.7431 13.3329V13.7179C21.7354 16.332 21.1243 18.9063 19.9609 21.2257C18.7974 23.5452 17.1154 25.5425 15.0553 27.0508Z"
        fill="#165F4D"
      />
    </StyledSpinner>
    <br />
    Loading...
  </StyledLoadingLogo>
);

const StyledSpinner = styled.svg`
  animation: rotate 3s linear infinite;
  & .path {
    stroke: #5652bf;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const StyledLoadingLogo = styled.div<{
    fontSize?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
  flex-direction: column;
  font-size: ${(props) => props.fontSize};
`;
