import { AppThunk, RootState } from './store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { mean, median, quantile } from 'd3-array';

import { formatDate } from '../components/Projects/sharedStyledComponents';

export interface TrendState {
  activeStudentsPerDay: any;
  selectedGraphKey: any;
  boxplot: any;
  pageStatus: boolean;
  trendProject: any;
  pageError: string;
  activityPerStudentPerDay: any;
  activeStudentsPerDayLoading: boolean;
  submissionsPerStudentPerDayLoading: boolean;
  hoursPerStudentPerDayLoading: boolean;
  scorePerStudentPerDayLoading: boolean;
}

const initialState: TrendState = {
  activeStudentsPerDay: { navigation: [], heartbeat: [], submission: [] },
  selectedGraphKey: {
    selectedActivity: { value: 'navigation', label: 'Navigation' },
    selectedBoxplot: {
      value: 'nSubmissions',
      label: '# of Submissions',
      text: '# of Submissions',
    },
  },
  boxplot: { nSubmissions: [] },
  pageStatus: false,
  trendProject: '',
  pageError: '',
  activityPerStudentPerDay: [],
  activeStudentsPerDayLoading: true,
  submissionsPerStudentPerDayLoading: true,
  hoursPerStudentPerDayLoading: true,
  scorePerStudentPerDayLoading: true,
};

export const trendSlice = createSlice({
  name: 'trend',
  initialState,
  reducers: {
    setTrendProject: (state, action: PayloadAction<any>) => {
      state.trendProject = action.payload;
    },
    resetTrendLoadings: (state) => {
      state.activeStudentsPerDayLoading = true;
      state.scorePerStudentPerDayLoading = true;
      state.submissionsPerStudentPerDayLoading = true;
      state.hoursPerStudentPerDayLoading = true;
    },
    updateActiveStudentsPerDay: (state, action: PayloadAction<any>) => {
      const eventTypes = [
        'navigation',
        'submission',
        'heartbeat',
        'reflection',
        'assessme',
      ];
      const studentActivities = action.payload;
      studentActivities.forEach(function (e: {
        date: string;
        full_date: string;
        shortened_date: string;
      }) {
        const full_date = formatDate(e.date);
        let shortened_date = full_date.slice(0, 5);

        shortened_date = shortened_date.startsWith('0')
          ? shortened_date.slice(1, 5)
          : shortened_date;
        e.full_date = full_date;
        e.shortened_date = shortened_date;
      });

      eventTypes.map((event) => {
        const filterForActivityType = (listOfActivities: {
          event_type: string;
        }) => listOfActivities.event_type === event;
        return (state.activeStudentsPerDay[event] = studentActivities.filter(
          filterForActivityType,
        ));
      });
      state.activeStudentsPerDayLoading = false;
    },

    updateActiveStudentsGraph: (state, action: PayloadAction<any>) => {
      state.selectedGraphKey[action.payload.graphType] = action.payload.event;
    },
    updateProjectScorePerStudentPerDay: (state, action: PayloadAction<any>) => {
      state.boxplot['score'] = action.payload;
      state.scorePerStudentPerDayLoading = false;
    },
    updateProjectSubmissionsPerStudentPerDay: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.boxplot['nSubmissions'] = action.payload;
      state.submissionsPerStudentPerDayLoading = false;
    },
    updateProjectHoursPerStudentPerDay: (state, action: PayloadAction<any>) => {
      state.boxplot['nHours'] = action.payload;
      state.hoursPerStudentPerDayLoading = false;
    },
    updateBoxplot: (state, action: PayloadAction<any>) => {
      const temp = action.payload;
      if (temp) {
        const days = Array.from(
          new Set(temp.map((n: { date: any }) => n.date)),
        );
        const data = days.map((d) => {
          const subset = temp.filter((n: { date: unknown }) => n.date === d);
          const submissions = subset.map(
            (s: { nSubmissions: any }) => s.nSubmissions,
          );
          const q1 = quantile(submissions, 0.25) || 0;
          const q3 = quantile(submissions, 0.75) || 0;
          const iqr = q3 - q1;
          const ll = q1 - 1.5 * iqr;
          const ul = q3 + 1.5 * iqr;

          const outliersData: { submission: number; index: number }[] = [];
          let i = 0;
          submissions.forEach(function (submission: number, index: number) {
            if (submission < ll || submission > ul) {
              outliersData[i] = { submission: submission, index: index };
              i++;
            }
          });

          return {
            date: d,
            students: subset.map((s: { email: any }) => s.email),
            submissions: submissions,
            totalSubmissions: [q1, Math.max(q3, 0.1)],
            medianSubmissions: median(submissions),
            meanSubmissions: mean(submissions),
            outliers: outliersData,
          };
        });
        state.boxplot = data;
      }
    },
    updatePageStatus: (state, action: PayloadAction<boolean>) => {
      state.pageStatus = action.payload;
    },
    updatePageError: (state, action: PayloadAction<any>) => {
      state.pageError = action.payload;
    },
  },
});

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

// ****************
// APIs
// ****************

const { REACT_APP_BACKEND_URI } = process.env;

export const getTrendsPerProject =
  (courseValue: string, project: any): AppThunk =>
  (dispatch) => {
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    if (courseValue && project.date_to_activate) {
      Promise.all([
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/ProjectScorePerStudentPerDay/${project.value}/${project.date_to_submit}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/recent/NSubmissionsPerStudentPerDay/${project.date_to_activate}/${project.date_to_submit}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/recent/NHoursPerStudentPerDay/${project.date_to_activate}/${project.date_to_submit}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/recent/nStudentsPerDayPerEventType/${project.date_to_activate}/${project.date_to_submit}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
      ])
        .then((responses) => {
          return Promise.all(
            responses.map(async function (response) {
              if (response.ok) return response.json();
              else {
                const errorText = await response.text();
                throw new Error(`${errorText} for ${response.url}`);
              }
            }),
          );
        })
        .then((jsonData) => {
          return jsonData.forEach(function (json, index) {
            switch (index) {
              case 0:
                dispatch(updateProjectScorePerStudentPerDay(json));
                break;
              case 1:
                dispatch(updateProjectSubmissionsPerStudentPerDay(json));
                break;
              case 2:
                dispatch(updateProjectHoursPerStudentPerDay(json));
                break;
              case 3:
                dispatch(updateActiveStudentsPerDay(json));
                break;
            }
          });
        })

        .then(() => {
          dispatch(setTrendProject(project));
          dispatch(updatePageStatus(true));
        })

        .catch((error) => {
          dispatch(updatePageError(error.toString()));
          dispatch(updatePageStatus(true));
        });
    }
  };

// **************************************************************
// Exports
// **************************************************************
export const {
  updatePageStatus,
  updateActiveStudentsPerDay,
  updateActiveStudentsGraph,
  updateBoxplot,
  setTrendProject,
  updatePageError,
  updateProjectScorePerStudentPerDay,
  updateProjectSubmissionsPerStudentPerDay,
  updateProjectHoursPerStudentPerDay,
  resetTrendLoadings,
} = trendSlice.actions;

export const trendTrendProject = (state: RootState) => state.trend.trendProject;
export const trendPageStatus = (state: RootState) => state.trend.pageStatus;
export const trendPageError = (state: RootState) => state.trend.pageError;
export const trendSelectedGraphKey = (state: RootState) =>
  state.trend.selectedGraphKey;
export const trendActiveStudentsPerDayLoading = (state: RootState) =>
  state.trend.activeStudentsPerDayLoading;

export const trendSubmissionsPerStudentPerDayLoading = (state: RootState) =>
  state.trend.submissionsPerStudentPerDayLoading;
export const trendScorePerStudentPerDayLoading = (state: RootState) =>
  state.trend.scorePerStudentPerDayLoading;
export const trendHoursPerStudentPerDayLoading = (state: RootState) =>
  state.trend.hoursPerStudentPerDayLoading;

export default trendSlice.reducer;
