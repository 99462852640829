import { AppThunk, RootState } from './store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SelectedProjectState {
  classHoursPerModule: Array<any>;
  activeStudentHoursPerModule: Array<any>;
  scorePerSubmission: any;
  pageStatus: boolean;
  pageError: string;
  activityPerEvent: any;
  hoursPerModuleLoading: boolean;
  activityByEventLoading: boolean;
  scorePerSubmissionLoading: boolean;
}

const initialState: SelectedProjectState = {
  classHoursPerModule: [],
  activeStudentHoursPerModule: [],
  scorePerSubmission: [],
  pageStatus: false,
  pageError: '',
  activityPerEvent: [],
  hoursPerModuleLoading: true,
  activityByEventLoading: true,
  scorePerSubmissionLoading: true,
};

export const selectedProjectSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    resetClassHoursPerModule: (state) => {
      state.classHoursPerModule = [];
      state.hoursPerModuleLoading = true;
    },
    updateClassHoursPerModule: (state, action: PayloadAction<any>) => {
      const classHoursPerModule = state.classHoursPerModule;
      const data = action.payload.hoursPerStudent;
      const classAverage = Math.round(
        data.reduce(
          (total: any, next: { hoursspent: any }) => total + next.hoursspent,
          0,
        ) / data.length,
      );

      const index = classHoursPerModule.findIndex(
        (data) => data.module === action.payload.module,
      );

      if (index === -1) {
        classHoursPerModule.push({
          module: action.payload.module,
          allStudents: action.payload.hoursPerStudent,
          avg: classAverage,
          studentHours: action.payload.studentHours,
        });
      }

      state.classHoursPerModule = classHoursPerModule;
      state.hoursPerModuleLoading = false;
    },

    updateActiveStudentHoursPerModule: (state, action: PayloadAction<any>) => {
      // state.activeStudentHoursPerModule = action.payload;

      state.classHoursPerModule.forEach((module: any) => {
        module.studentHours =
          module.allStudents.find(
            (e: { email: string }) => e.email === action.payload,
          ).hoursspent || 0;
      });
      state.hoursPerModuleLoading = false;
    },

    updateScorePerSubmission: (state, action: PayloadAction<any>) => {
      const scorePerSubmission = action.payload;
      state.scorePerSubmission = scorePerSubmission;
      state.scorePerSubmissionLoading = false;
    },
    clearActiveStudent: (state) => {
      state.scorePerSubmission = [];
      state.hoursPerModuleLoading = true;
      state.scorePerSubmissionLoading = true;
      state.activityByEventLoading = true;
      // state.classHoursPerModule = [];
    },
    updatePageStatus: (state, action: PayloadAction<boolean>) => {
      state.pageStatus = action.payload;
    },
    updatePageError: (state, action: PayloadAction<any>) => {
      state.pageError = action.payload;
    },
    updateActivityPerEvent: (state, action: PayloadAction<any>) => {
      state.activityPerEvent = action.payload;
      state.activityByEventLoading = false;
    },
  },
});

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

// ****************
// APIs
// ****************

const { REACT_APP_BACKEND_URI } = process.env;

export const getClassHoursPerModule =
  (courseValue: string, project: any, primers: any): AppThunk =>
  (dispatch, getState) => {
    const relevantPrimers: any = [];
    const studentValue = getState().navbar.activeStudent.value;
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);

    if (courseValue && project.value && studentValue) {
      dispatch(resetClassHoursPerModule());
      fetch(
        `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/nHoursPerStudent/${project.value}`,
        {
          method: 'GET',
          headers: headers,
        },
      )
        .then(async (response) => {
          if (response.ok) return response.json();
          else {
            const errorText = await response.text();
            throw new Error(`${errorText} for ${response.url}`);
          }
        })
        .then((myJson) => {
          const studentHours =
            myJson.find((e: { email: string }) => e.email === studentValue)
              ?.hoursspent || 0;

          const classHoursPerModule = {
            module: project.label,
            hoursPerStudent: myJson,
            studentHours: studentHours,
          };
          dispatch(updateClassHoursPerModule(classHoursPerModule));
        })
        .then(() => {
          if (primers[0]) {
            fetch(
              `${REACT_APP_BACKEND_URI}/api/${courseValue}/meta/primerMappings/${project.value}`,
              {
                method: 'GET',
                headers: headers,
              },
            )
              .then(async (response) => {
                if (response.ok) return response.json();
                else {
                  const errorText = await response.text();
                  throw new Error(`${errorText} for ${response.url}`);
                }
              })
              .then((myJson) => {
                myJson.forEach((preReq: any) => {
                  const primer = primers.find(
                    (e: { value: any }) => e.value === preReq.prereq_slug,
                  );

                  relevantPrimers.push(primer);
                });
                return relevantPrimers;
              })
              .then((primersList) => {
                if (primersList[0]) {
                  primersList.forEach((primer: any) => {
                    fetch(
                      `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/nHoursPerStudent/${primer.value}`,
                      {
                        method: 'GET',
                        headers: headers,
                      },
                    )
                      .then(async (response: any) => {
                        if (response.ok) return response.json();
                        else {
                          const errorText = await response.text();
                          throw new Error(`${errorText} for ${response.url}`);
                        }
                      })
                      .then((myJson: any) => {
                        const studentHours =
                          myJson.find(
                            (e: { email: string }) => e.email === studentValue,
                          ).hoursspent || 0;

                        const classHoursPerModule = {
                          module: primer.primer_name,
                          hoursPerStudent: myJson,
                          studentHours: studentHours,
                        };
                        dispatch(
                          updateClassHoursPerModule(classHoursPerModule),
                        );
                      })
                      .catch((error) => {
                        dispatch(updatePageError(error.toString()));
                        dispatch(updatePageStatus(true));
                      });
                  });
                } else {
                  primers.forEach((primer: any) => {
                    if (
                      primer.date_to_activate <= project.date_to_submit &&
                      primer.date_to_submit >= project.date_to_activate
                    ) {
                      fetch(
                        `${REACT_APP_BACKEND_URI}/api/${courseValue}/project/nHoursPerStudent/${primer.value}`,
                        {
                          method: 'GET',
                          headers: headers,
                        },
                      )
                        .then(async (response) => {
                          if (response.ok) return response.json();
                          else {
                            const errorText = await response.text();
                            throw new Error(`${errorText} for ${response.url}`);
                          }
                        })
                        .then((myJson) => {
                          const studentHours =
                            myJson.find(
                              (e: { email: string }) =>
                                e.email === studentValue,
                            ).hoursspent || 0;

                          const classHoursPerModule = {
                            module: primer.primer_name,
                            hoursPerStudent: myJson,
                            studentHours: studentHours,
                          };
                          dispatch(
                            updateClassHoursPerModule(classHoursPerModule),
                          );
                        })
                        .catch((error) => {
                          dispatch(updatePageError(error.toString()));
                          dispatch(updatePageStatus(true));
                        });
                    }
                  });
                }
              });
          }
        })
        .catch((error) => {
          dispatch(updatePageError(error.toString()));
          dispatch(updatePageStatus(true));
        });
    }
  };

export const getStudentDataForSelectedProject =
  (courseValue: string, project: any, studentValue: string): AppThunk =>
  (dispatch) => {
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);

    if (courseValue && project.value && studentValue) {
      Promise.all([
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/student/eventspereventtype/${studentValue}/${project.date_to_activate}/${project.date_to_submit}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/student/scorepersubmission/${studentValue}/${project.value}`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
      ])
        .then((responses) => {
          return Promise.all(
            responses.map(async function (response) {
              if (response.ok) return response.json();
              else {
                const errorText = await response.text();
                throw new Error(`${errorText} for ${response.url}`);
              }
            }),
          );
        })
        .then((jsonData) => {
          jsonData.forEach(function (json, index) {
            switch (index) {
              case 0:
                dispatch(updateActivityPerEvent(json));
                break;
              case 1:
                dispatch(updateScorePerSubmission(json));
                break;
            }
          });
        })
        .catch((error) => {
          dispatch(updatePageError(error.toString()));
          dispatch(updatePageStatus(true));
        })
        .then(() => {
          dispatch(updatePageStatus(true));
        });
    }
  };

// **************************************************************
// Exports
// **************************************************************
export const {
  resetClassHoursPerModule,
  updateClassHoursPerModule,
  updateActiveStudentHoursPerModule,
  updateScorePerSubmission,
  clearActiveStudent,
  updatePageStatus,
  updatePageError,
  updateActivityPerEvent,
} = selectedProjectSlice.actions;

export const selectedProjectPageError = (state: RootState) =>
  state.selectedProject.pageError;
export const selectedProjectHoursPerModuleLoading = (state: RootState) =>
  state.selectedProject.hoursPerModuleLoading;
export const selectedProjectActivityByEventLoading = (state: RootState) =>
  state.selectedProject.activityByEventLoading;
export const selectedProjectScorePerSubmissionLoading = (state: RootState) =>
  state.selectedProject.scorePerSubmissionLoading;

export default selectedProjectSlice.reducer;
