import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// Helper function to sort module names numerically
const sortModules = (a, b) => {
  const moduleA = a.module_name.match(/^P\d+/);
  const moduleB = b.module_name.match(/^P\d+/);

  if (moduleA && moduleB) {
    return moduleA[0].localeCompare(moduleB[0], undefined, { numeric: true });
  }
  return a.module_name.localeCompare(b.module_name); // Fallback for other module names
};

// Helper function to create color mapping
const generateColorMap = (modules) => {
  const colors = [
    '#8884d8',
    '#82ca9d',
    '#ffc658',
    '#ff7f50',
    '#0088fe',
    '#00c49f',
    '#ffbb28',
    '#ff8042',
    '#a4de6c',
    '#d0ed57',
    '#8dd1e1',
    '#83a6ed',
    '#d5a6bd',
    '#f6c3c1',
  ];

  const colorMap = {};
  modules.forEach((module, index) => {
    colorMap[module] = colors[index % colors.length];
  });

  return colorMap;
};

const AllProjectBarChart = (props: { data: any; type: any; header: any }) => {
  // Step 1: Transform the data
  const data = props.data;
  const key = props.type;
  const header = props.header;
  const chartData = Object.keys(data).map((module_name) => ({
    module_name,
    [key]: data[module_name],
  }));
  // console.log("[chartData]", chartData, key)

  // Step 2: Sort the data based on module_name
  const sortedChartData = chartData.sort(sortModules);
  // console.log("[sortedChartData]", sortedChartData)

  // Step 3: Create color mapping
  const colorMap = generateColorMap(sortedChartData.map((d) => d.module_name));

  return (
    <div>
      {header && <h2 style={{ textAlign: 'center' }}>{header}</h2>}
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={sortedChartData}
            margin={{ top: 20, right: 30, left: 150, bottom: 100 }} // increase bottom margin for space for the labels and the x-axis name
            height={sortedChartData.length * 40 + 100} // Dynamically adjust height based on number of items
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              label={{ value: header, position: 'insideBottom', offset: -10 }}
              tick={{ fill: '#666' }}
            />
            <YAxis
              type="category"
              dataKey="module_name"
              width={140} // Increase width for labels
              tick={(props) => {
                const { x, y, payload } = props;
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      x={0}
                      y={0}
                      dy={4}
                      textAnchor="end"
                      fill="#666"
                      fontSize={12}
                      transform="rotate(-35)"
                    >
                      {payload.value}
                    </text>
                  </g>
                );
              }}
            />
            <Tooltip />
            <Bar dataKey={key} barSize={20}>
              {sortedChartData.map((entry, index) => (
                <Cell
                  key={entry.module_name}
                  fill={colorMap[entry.module_name]}
                />
              ))}
              <LabelList
                dataKey={key}
                position="right"
                style={{ fontSize: '14px', fill: '#333' }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AllProjectBarChart;
