import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
} from '../sharedStyledComponents';

import { updateActiveStudent } from '../../../app/navbarSlice';

const Scatterplot = (props: {
  gridClass: string;
  title: string;
  xAxisLabel: string;
  xDataKey: string;
  yAxisLabel: string;
  yDataKey: string;
}) => {
  const students = useAppSelector((state) => state.snapshot.students);
  const allStudents = students.map((student: any) => {
    if (!student.nSubmissions) return { ...student, nSubmissions: 0 };
    else return { ...student };
  });

  const activeStudent = useAppSelector((state) => state.navbar.activeStudent);
  const reduxDispatch = useAppDispatch();

  const handleClick = (data: any) => {
    reduxDispatch(
      updateActiveStudent({
        full_name: data.full_name,
        email: data.email,
        label: data.label,
        value: data.value,
      }),
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload || payload.length <= 0) return null;
    return (
      <div>
        <p>
          {payload[0].payload.full_name ||
            payload[0].payload.email.split('@')[0]}
        </p>
      </div>
    );
  };

  const plotFontSize = '.75rem';

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph width={'full'}>
        <StyledGraphHeader>
          <StyledGraphTitle>{props.title}</StyledGraphTitle>
        </StyledGraphHeader>
        <ResponsiveContainer height="85%">
          <ScatterChart>
            <CartesianGrid strokeDasharray="10 10 " />
            <XAxis
              type="number"
              label={{
                fontSize: plotFontSize,
                fill: `rgba(19, 44, 62, 0.7)`,
                value: props.xAxisLabel,
                position: 'outside',
                dy: 14,
              }}
              dataKey={props.xDataKey}
              name={props.xDataKey}
              tick={{
                fontSize: plotFontSize,
                fill: `rgba(19, 44, 62, 0.7)`,
              }}
              allowDuplicatedCategory={false}
            />
            <YAxis
              type="number"
              label={{
                fontSize: plotFontSize,
                fill: `rgba(19, 44, 62, 0.7)`,
                value: props.yAxisLabel,
                position: 'outside',
                angle: '270',
                dx: -10,
              }}
              dataKey={props.yDataKey}
              name={props.yDataKey}
              tick={{
                fontSize: plotFontSize,
                fill: `rgba(19, 44, 62, 0.7)`,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Scatter
              name={props.title}
              data={allStudents}
              fill="rgba(19, 44, 62, 0.3)"
              onClick={handleClick}
            >
              {allStudents.map((entry: { email: any }, index: any) => (
                <Cell
                  cursor="pointer"
                  fill={
                    entry.email === activeStudent.value
                      ? '#2071DE'
                      : 'rgba(19, 44, 62, 0.2)'
                  }
                  stroke={'#2071DE'}
                  strokeWidth={entry.email === activeStudent.value ? 4 : 0}
                  key={`cell-${index}`}
                />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </StyledGraph>
    </StyledGraphContainer>
  );
};

export default Scatterplot;
