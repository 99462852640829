import {
  StyledGraph,
  StyledGraphContainer,
  StyledGraphHeader,
  StyledGraphTitle,
  StyledNoDataResponse,
} from '../Projects/sharedStyledComponents';
import {
  navbarActiveProject,
  navbarActiveStudent,
} from '../../app/navbarSlice';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useAppSelector } from '../../app/hooks';

const ActivityByEvent = (props: { gridClass: string }) => {
  const activityPerEvent = useAppSelector(
    (state) => state.selectedProject.activityPerEvent,
  );
  const project = useAppSelector(navbarActiveProject);
  const activeStudent = useAppSelector(navbarActiveStudent);

  const Plot = createPlotlyComponent(Plotly);

  function color(eventType: string) {
    if (eventType === 'navigation') return '#ACEAFF';
    if (eventType === 'heartbeat') return '#409DBA';
    if (eventType === 'assessme') return '#204E5D';
    if (eventType === 'submission') return '#FDA529';
    if (eventType === 'reflection') return '#FDCE29';
  }

  const eventTypes = [
    'navigation',
    'heartbeat',
    'assessme',
    'submission',
    'reflection',
  ];

  const data: any = [];
  eventTypes.forEach((t) => {
    const primerEvents = activityPerEvent.filter(
      (e: { event_type: string; module_slug: any }) =>
        e.event_type === t && !project.value.includes(e.module_slug),
    );

    const projectEvents = activityPerEvent.filter(
      (e: { event_type: string; module_slug: any }) =>
        e.event_type === t && project.value.includes(e.module_slug),
    );

    const moduleEvents = projectEvents.concat(primerEvents);

    data.push({
      x: moduleEvents.map((e: { event_time: any }) => new Date(e.event_time)),
      y: moduleEvents.map((e: { module_name: any }) => e.module_name),
      marker: { color: color(t), size: 10 },
      mode: 'markers',
      name: t,
      type: 'scatter',
      hovertemplate: `Time: %{x} <br /> Module: %{y} `,
    });
  });

  return (
    <StyledGraphContainer gridClass={props.gridClass}>
      <StyledGraph width={'full'}>
        <StyledGraphHeader>
          <StyledGraphTitle>
            {activeStudent.label}'s Activity By Event
          </StyledGraphTitle>
        </StyledGraphHeader>
        {data[0].x[0] ||
        data[1].x[0] ||
        data[2].x[0] ||
        data[3].x[0] ||
        data[4].x[0] ? (
          <Plot
            data={data}
            layout={{
              font: {
                family: 'Fira Sans, sans-serif',
                size: 12,
              },
              autosize: true,
              showlegend: true,
              legend: { orientation: 'h', x: -0.5, y: 1.1 },
              hovermode: 'closest',
              margin: { l: 300, r: 100, b: 50, t: 25 },
              xaxis: {
                range: [
                  new Date(project.date_to_activate),
                  new Date(project.date_to_submit),
                ],
                hoverformat: '%m/%d %I:%M %p',
              },
            }}
            style={{ width: '100%', height: '90%' }}
            useResizeHandler
            config={{
              modeBarButtonsToRemove: [
                'lasso2d',
                'select2d',
                'sendDataToCloud',
                'autoScale2d',
                'hoverClosestCartesian',
                'hoverCompareCartesian',
                'hoverClosestGl2d',
                'toggleHover',
                'toggleSpikelines',
              ],
            }}
          />
        ) : (
          <StyledNoDataResponse>
            {activeStudent.label} has no activity yet for this project.{' '}
          </StyledNoDataResponse>
        )}
      </StyledGraph>
    </StyledGraphContainer>
  );
};

export default ActivityByEvent;
