import { Button } from '@material-ui/core';
import Select from 'react-select';
import styled from 'styled-components';
import { theme } from '../sharedStyles/Theme';
import { useAppSelector } from '../app/hooks';

export interface SelectOption {
  value: string;
  label: string;
}

export const Selector = (props: {
  label: string;
  isSearchable: boolean;
  isDisabled: boolean;
  isMulti?: boolean;
  optionsReduxSelector: any;
  selectedReduxSelector: any;
  handleSelect: any;
  hasButton: boolean;
  buttonLabels?: string[];
  buttonClickHandlers?: any[];
  otherChildren?: any[];
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  placeholder?: string;
}) => {
  const options: SelectOption[] = useAppSelector(props.optionsReduxSelector);
  const selected: SelectOption = useAppSelector(props.selectedReduxSelector);

  const customStyles = {
    option: (styles: any, { isFocused }: any) => {
      return {
        ...styles,
        backgroundColor: isFocused ? theme.colors.textBackground : null,
        color: 'black',
        fontSize: '16px',
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      alignItems: 'center',
      textAlign: 'center',
      verticalAlign: 'top',
      height: '3rem',
      fontSize: '16px',
      padding: '1% 0 1% 0',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'rgba(19, 44, 62, 0.75)',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      height: '3rem',
      margin: '0 0 2% 0',
      backgroundColor: 'white',
      boxShadow: state.isFocused ? 0 : 0,
      border: state.isFocused
        ? `2px solid ${theme.colors.brandDark}`
        : `2px solid transparent`,
      '&:hover': {
        border: state.isFocused
          ? `2px solid ${theme.colors.brandDark}`
          : `2px solid lightgrey`,
      },
      color: 'rgba(19, 44, 62, 0.75)',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: 'rgba(19, 44, 62, 0.75)',
      width: '1.4rem',
      padding: '1% 1% 1% 1%',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 99 }),
  };

  return (
    <Container
      height={props.height ? props.height : '5rem'}
      width={props.width}
      margin={props.margin}
    >
      <label
        style={{
          width: '100%',
          height: '1.1rem',
          alignItems: 'center',
          textAlign: 'center',
          display: 'inline-block',
          margin: '0% 0 2% 0',
          overflowY: 'hidden',
        }}
      >
        {props.label}
      </label>

      <Select
        options={options}
        styles={customStyles}
        menuPlacement="auto"
        menuPosition="fixed"
        isSearchable={props.isSearchable}
        isOptionDisabled={() =>
          Array.isArray(selected) ? selected.length >= 2 : false
        }
        isDisabled={props.isDisabled}
        menuPortalTarget={document.body}
        isMulti={props.isMulti} // new code tommy
        onChange={(event: any) => props.handleSelect(event)}
        value={
          props.isMulti
            ? selected
            : { value: selected.value, label: selected.label }
        }
        placeholder={props.placeholder ? props.placeholder : ''}
      />

      {props.hasButton && props.buttonLabels
        ? props.buttonLabels.map((buttonLabel: string, idx: number) => {
            if (props.buttonClickHandlers) {
              const buttonClickHandler = props.buttonClickHandlers[idx];
              return (
                <Button
                  disabled={props.isDisabled}
                  onClick={() => buttonClickHandler()}
                  style={{
                    height: '2rem',
                    width: '49%',
                    overflowY: 'hidden',
                    display: 'inline-block',
                    backgroundColor: theme.colors.white,
                    color: 'rgba(19, 44, 62, 0.75)',
                    margin: idx % 2 === 0 ? '1% 1% 1% 0' : '1% 0 1% 1%',
                  }}
                >
                  {buttonLabel}
                </Button>
              );
            }
          })
        : ''}
      {props.otherChildren
        ? props.otherChildren.map((child: any) => child)
        : ''}
    </Container>
  );
};

export const Container = styled.div<{
  height?: string;
  width?: string;
  margin?: string;
  padding?: string;
}>`
  color: rgba(19, 44, 62, 0.75);
  height: ${(props) => props.height};
  border-radius: 7px;
  background: ${theme.colors.textBackground};
  display: inline-block;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-start;
  text-align: center;
  vertical-align: top;
  ${(props) => (props.width ? 'width: ' + props.width + ';' : 'width: 88%;')}
  ${(props) =>
    props.margin ? 'margin: ' + props.margin + ';' : 'margin: 2% 4% 2% 4%;'}
  ${(props) =>
    props.padding ? 'padding: ' + props.padding + ';' : 'padding: 2% 2% 2% 2%;'}
`;
