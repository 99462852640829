import {
  ExpandImg,
  Left,
  Right,
  StyledStudent,
  shortenStudentName,
} from './sharedStyledComponents';
import {
  navbarActiveCourse,
  navbarActiveProject,
  updateActiveStudent,
} from '../../app/navbarSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { createStudentLabelValue } from './sharedStyledComponents';

const Student = (props: any) => {
  const courseValue = useAppSelector(navbarActiveCourse).value;
  const projectValue = useAppSelector(navbarActiveProject).value;
  const reduxDispatch = useAppDispatch();

  const redirectToStudent = (student: any) => {
    reduxDispatch(updateActiveStudent(createStudentLabelValue(student)));
  };
  return (
    <StyledStudent
      to={{
        pathname: `/${courseValue}/${projectValue}/students/selected-project`,
      }}
      data-tip={props.student?.value || ''}
      onClick={() => redirectToStudent(props.student)}
    >
      <Left>
        {props.student?.label
          ? shortenStudentName(props.student.label)
          : props.student?.value
            ? shortenStudentName(props.student.value)
            : props.student?.name
              ? shortenStudentName(props.student.name)
              : shortenStudentName(props.student?.email)}
      </Left>
      <Right>
        <ExpandImg
          src="/images/button-expand-right.svg"
          alt={`Expand Snapshot Card for ${props.student?.value}`}
        />
      </Right>
    </StyledStudent>
  );
};

export default Student;
