import {
  allProjectsSelectedGraphKey,
  updateActiveStudentsGraph as updateActiveStudentsGraphForAllProjects,
} from '../../../app/allProjectsSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { theme } from '../../../sharedStyles/Theme';
import { updateActiveStudentsGraph as updateActiveStudentsGraphForTrend } from '../../../app/trendSlice';

const StyledGraphKeySelect = (props: {
  keyOptions: { value: string; label: string }[];
  graphType: string;
  page: string;
}) => {
  const reduxDispatch = useAppDispatch();

  const selectedGraphKeyForTrend = useAppSelector(
    (state) => state.trend.selectedGraphKey[props.graphType],
  );

  const selectedGraphKeyForAllProjects = useAppSelector(
    allProjectsSelectedGraphKey,
  )[props.graphType];

  const handleChange = (event: any) => {
    switch (props.page) {
      case 'trend':
        reduxDispatch(
          updateActiveStudentsGraphForTrend({
            event: event,
            graphType: props.graphType,
          }),
        );
        break;
      case 'allProjects':
        reduxDispatch(
          updateActiveStudentsGraphForAllProjects({
            event: event,
            graphType: props.graphType,
          }),
        );
        break;
    }
  };

  const customStyles = {
    option: (styles: any, { isFocused }: any) => {
      return {
        ...styles,
        backgroundColor: isFocused ? theme.colors.textBackground : null,
        color: 'black',
        fontSize: '.75rem',
      };
    },
    control: (provided: any, state: any) => ({
      ...provided,

      backgroundColor: 'white',
      boxShadow: state.isFocused ? 0 : 0,

      border: '2px solid rgba(19, 44, 62, 0.2)',
      '&:hover': {
        border: state.isFocused
          ? `2px solid ${theme.colors.brandDark}`
          : `2px solid lightgrey`,
      },
      color: 'rgba(19, 44, 62, 0.75)',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: () => ({
      color: 'rgba(19, 44, 62, 0.75)',
      fontSize: '.75rem',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: 'rgba(19, 44, 62, 0.75)',
      padding: '0 .1rem 0 0',
    }),

    menuPortal: (provided: any) => ({ ...provided, zIndex: 99 }),
  };

  return (
    <StyledProjectSelect>
      <Select
        options={props.keyOptions}
        styles={customStyles}
        menuPlacement="auto"
        menuPosition="fixed"
        isSearchable={false}
        menuPortalTarget={document.body}
        onChange={(event: any) => handleChange(event)}
        value={
          props.page === 'trend'
            ? selectedGraphKeyForTrend
            : selectedGraphKeyForAllProjects
        }
      />
    </StyledProjectSelect>
  );
};

export default StyledGraphKeySelect;

const StyledProjectSelect = styled.div`
  color: rgba(19, 44, 62, 0.75);
  display: flex;
  align-items: center;
`;
