import {TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { StyledTable } from "../../../sharedStyles/sharedStyledComponents";
import {activeCaseMetaData, CaseMetaDataStudentInfo} from "../../../app/navbarQuizCheatCheckingSlice";
import {useAppSelector} from "../../../app/hooks";
import {studentInfo} from "../../../app/quizCheatCheckingSlice";

export const StudentInfoTable = () => {
    const studentInfoRedux = useAppSelector(studentInfo);
    const activeCaseMetaDataRedux = useAppSelector(activeCaseMetaData);
    return (
        <TableContainer style={{
            padding: '1rem 1rem 1rem 1rem',
            width: '96%'
        }}>
            <StyledTable key='StudentInfoTable'>
                <TableHead key='StudentInfoTableHead'>
                    <TableRow key='StudentInfoTableHeadRow'>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>First Name</StyledTableCell>
                        <StyledTableCell>Last Name</StyledTableCell>
                        <StyledTableCell>College</StyledTableCell>
                        <StyledTableCell>Department</StyledTableCell>
                        <StyledTableCell>Course</StyledTableCell>
                        <StyledTableCell>Section</StyledTableCell>
                        <StyledTableCell>Team</StyledTableCell>
                        <StyledTableCell>Time Spent</StyledTableCell>
                        <StyledTableCell>Score</StyledTableCell>
                        <StyledTableCell>Used GraceDay</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody key='StudentInfoTableBody'>
                    {activeCaseMetaDataRedux.studentInfo.map((caseMetaDataStudentInfo: CaseMetaDataStudentInfo) => (
                        <TableRow key={caseMetaDataStudentInfo.id}>
                            <StyledTableCell>{caseMetaDataStudentInfo.id}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].firstName ? studentInfoRedux[caseMetaDataStudentInfo.id].firstName : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].lastName ? studentInfoRedux[caseMetaDataStudentInfo.id].lastName : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].college ? studentInfoRedux[caseMetaDataStudentInfo.id].college : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].department ? studentInfoRedux[caseMetaDataStudentInfo.id].department : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].course ? studentInfoRedux[caseMetaDataStudentInfo.id].course : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].campus ? studentInfoRedux[caseMetaDataStudentInfo.id].campus : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].team ? studentInfoRedux[caseMetaDataStudentInfo.id].team : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].quizTimeDuration ? studentInfoRedux[caseMetaDataStudentInfo.id].quizTimeDuration : "no data"} Minutes</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].quizScore ? studentInfoRedux[caseMetaDataStudentInfo.id].quizScore : "no data"}</StyledTableCell>
                            <StyledTableCell>{studentInfoRedux[caseMetaDataStudentInfo.id] && studentInfoRedux[caseMetaDataStudentInfo.id].usedGraceDayNumber !== undefined && studentInfoRedux[caseMetaDataStudentInfo.id].usedGraceDayNumber !== null ? (Number(studentInfoRedux[caseMetaDataStudentInfo.id].usedGraceDayNumber) === -1 ? "" : (studentInfoRedux[caseMetaDataStudentInfo.id].usedGraceDayNumber + " Days")) : "no data"}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

const StyledTableCell = withStyles((theme) => ({
    root: {
        fontSize: 14,
        fontWeight: 600,
        color: "#132C3E",
        fontFamily: "Fira Sans",
    },
    head: {
        backgroundColor: "#E8F9F4",
        padding: "1rem 1rem",
    },
    body: {
        padding: ".6rem 1rem",
    },
}))(TableCell);
