import {
  allProjectsActivityPerStudentPerProjectLoading,
  allProjectsPageError,
  clearActiveStudent,
  getActivityPerStudentPerProject,
  getStudentDataForProjectSubmissions,
  updatePageStatus,
} from '../../app/allProjectsSlice';
import {
  navbarActiveCourse,
  navbarComponentError,
} from '../../app/navbarSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import ActivityPerStudentPerProject from '../../components/Students/ActivityPerStudentPerProject';
import Error from '../Error';
import { LoadingOverlay } from '../../svgs/loading-overlay';
import ProjectSubmissionsTimeline from '../../components/Students/ProjectSubmissionsTimeline';
import StudentsNavBar from '../../components/Students/StudentsNavBar';
import { StyledGrid } from '../../components/Projects/sharedStyledComponents';
import Theme from '../../sharedStyles/Theme';
import styled from 'styled-components';
import { useEffect } from 'react';

const AllProjects = () => {
  const reduxDispatch = useAppDispatch();

  const student = useAppSelector((state) => state.navbar.activeStudent);
  const courseValue = useAppSelector(navbarActiveCourse).value;
  const pageError = useAppSelector(allProjectsPageError);
  const activityPerStudentPerProjectLoading = useAppSelector(
    allProjectsActivityPerStudentPerProjectLoading,
  );

  const componentError = useAppSelector(navbarComponentError);

  useEffect(() => {
    reduxDispatch(clearActiveStudent());
    reduxDispatch(getStudentDataForProjectSubmissions());
    reduxDispatch(getActivityPerStudentPerProject());
    return () => {
      reduxDispatch(updatePageStatus(false));
    };
  }, [reduxDispatch, student, courseValue]);

  return courseValue === '404' || componentError ? (
    <Error errorType={404} />
  ) : pageError ? (
    <Error errorType={500} errorMessage={pageError} />
  ) : (
    <Theme>
      <StudentsNavBar hideProjectsSelect={true} />

      <StyledAllProjects>
        <ProjectSubmissionsTimeline gridClass={'one'} />

        <ActivityPerStudentPerProject gridClass={'two'} />
        <LoadingOverlay
          view={activityPerStudentPerProjectLoading}
          gridClass={'two'}
        />
      </StyledAllProjects>
    </Theme>
  );
};

export default AllProjects;

const StyledAllProjects = styled(StyledGrid)`
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'one two' 'one two' 'one .';
`;
