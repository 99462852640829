import {
  StyledGraphActiveDataTwo,
  StyledGraphActiveDataTwoTitle,
} from '../sharedStyledComponents';

import React from 'react';
import Student from '../Student';
import { navbarActiveStudent } from '../../../app/navbarSlice';
import { snapshotStudents } from '../../../app/snapshotSlice';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';

const ScatterplotSelectedStudent = (props: { gridClass: string }) => {
  const students = useAppSelector(snapshotStudents);
  const activeStudent = useAppSelector(navbarActiveStudent);
  const activeStudentWithData = students.find(
    (student: { email: string }) => student.email === activeStudent.value,
  );

  return (
    <StyledGraphActiveDataTwo gridClass={props.gridClass}>
      <StyledGraphActiveDataTwoTitle>
        Selected Student:
      </StyledGraphActiveDataTwoTitle>
      <Student student={activeStudentWithData} />
      <StyledStats>
        <a href={`mailto:${activeStudentWithData?.email || ''}`}>
          {' '}
          {activeStudentWithData?.email}
        </a>
        <div>Hours Spent: {activeStudentWithData?.hoursspent || '0'}</div>
        <div>Score: {activeStudentWithData?.module_score || '0'}</div>
        <div>
          # of Submissions: {activeStudentWithData?.nSubmissions || '0'}
        </div>
      </StyledStats>
    </StyledGraphActiveDataTwo>
  );
};

export default ScatterplotSelectedStudent;

const StyledStats = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;

  * {
    padding: 0.5rem;
    overflow-wrap: break-word;
  }

  *:first-child {
    color: rgba(19, 44, 62, 0.75);
    padding-bottom: 1rem;
    font-size: 0.75rem;
  }
`;
