import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const RechartsLineChart = (props: {data: any, header: any}) => (
    <div>
    {props.header && <h2 style={{ textAlign: 'center' }}>{props.header}</h2>}
    <ResponsiveContainer width="100%" height={400}>
        <LineChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hour" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
    </ResponsiveContainer>
  </div>
);

export default RechartsLineChart;