import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const RechartsBarChartHorizontal = (props: { data: any, header:any}) => {
  const data = props.data;

  // Grouping scores by ranges of 10 points and counting occurrences
  // const scoreCounts = data.reduce((acc, item) => {
  //   const scoreRange = Math.floor(item.module_score / 10) * 10; // Calculate the range (e.g., 0, 10, 20, etc.)
  //   if (acc[scoreRange]) {
  //     acc[scoreRange] += 1;
  //   } else {
  //     acc[scoreRange] = 1;
  //   }
  //   return acc;
  // }, {});

  // // Converting the scoreCounts object into an array for Recharts
  // const chartData = Object.keys(scoreCounts).map(range => ({
  //   scoreRange: `${range}-${parseInt(range) + 9}`, // e.g., "0-9", "10-19"
  //   count: scoreCounts[range],
  // }));

  

  return (
    <div>
      {props.header && <h2 style={{ textAlign: 'center' }}>{props.header}</h2>}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart 
          data={data} 
          width={800}
          height={300}
          margin={{ top: 20, right: 100, left: 50, bottom: 40 }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            type="number"
            label={{ value: 'Number of Students', position: 'insideBottom', offset: -10 }}
            tick={{ fill: '#666' }}
          />
          <YAxis 
            type="category"
            dataKey="activity_name"
            label={{ value: 'Task Name', angle: -90, position: 'insideLeft', dx: 0 }}  // Adjust dx to move the label left
            tick={{ fill: '#666', fontSize: 12}}  // Ensure text does not wrap
            width={200}
          />
          <Tooltip />
          <Bar dataKey="num_students" fill="#82ca9d">
            <LabelList dataKey="num_students" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RechartsBarChartHorizontal;

