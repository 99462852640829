import styled from "styled-components";
import theme from "./Theme"
import {withStyles} from "@material-ui/core/styles";
import {Table} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

const SnapshotCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const UnexpandedSnapshotCard = styled.button<{ onClick: any }>`
  border: none;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  width: 90%;
  height: 100%;
`;

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
`;

export const GridLayout = styled.div<{
    gridClass?: string;
    gridTemplateColumns?: string;
    gridTemplateAreas?: string;
    height?: string;
    gridColumnGap?: string;
    gridRowGap?: string;
    margin?: string;
    padding?: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-areas: ${(props) => props.gridTemplateAreas};
  ${(props) => {if (props.height) return 'height: ' + props.height + ';'}}
  ${(props) => {if (props.gridClass) return 'grid-area: ' + props.gridClass + ';'}}
  ${(props) => {if (props.gridColumnGap) return 'grid-column-gap: ' + props.gridColumnGap + ';'}}
  ${(props) => {if (props.gridRowGap) return 'grid-row-gap: ' + props.gridRowGap + ';'}}
  ${(props) => {if (props.margin) return 'margin: ' + props.margin + ';'}}
  ${(props) => {if (props.padding) return 'padding: ' + props.padding + ';'}}
`;

export const Container = styled.div<{
    height?: string;
    minHeight?: string;
    backgroundColor?: string;
    margin?: string;
    padding?: string;
    hasBoxShadow?: boolean;
    display?: string;
    flexWrap?: string;
}>`
  border-radius: 7px;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  ${(props) => {if (props.hasBoxShadow) return 'box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);'}}
  ${(props) => {if (props.height) return 'height: ' + props.height + ';'}}
  ${(props) => {if (props.minHeight) return 'min-height: ' + props.minHeight + ';'}}
  ${(props) => {if (props.backgroundColor) return 'background: ' + props.backgroundColor + ';'}}
  ${(props) => {if (props.margin) return 'margin: ' + props.margin + ';'}}
  ${(props) => {if (props.padding) return 'padding: ' + props.padding + ';'}}
  ${(props) => {if (props.display) return 'display: ' + props.display + ';'}}
  ${(props) => {if (props.flexWrap) return 'flex-wrap: ' + props.flexWrap + ';'}}
`;

export const GriddedContainer = styled(Container)<{
    gridClass?: string;
}>`
  grid-area: ${(props) => props.gridClass};
  grid-template-rows: auto;
`;

export const ContainerHeader = styled.div<{
    width?: string;
    height?: string;
}>`
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 0;
  ${(props) => {if (props.width) return 'width: ' + props.width + ';'}}
  ${(props) => {if (props.height) return 'height: ' + props.height + ';'}}
`;

export const ContainerTitle = styled.div`
  text-align: center;
  padding: 0 0 0 0;
`;

export const StyledTable = styled(Table)<{
    background?: string;
    boxShadow?: string;
    borderRadius?: string;
}>`
    ${(props) => {
        if (props.background) return 'background: ' + props.background + ';'
        else return 'background: #FFFFFF;';
    }}
    ${(props) => {
        if (props.boxShadow) return 'boxShadow: ' + props.boxShadow + ';'
        else return 'boxShadow: 0px 0px 10px rgba(0, 0, 0, 0.1);';
    }}
    ${(props) => {
        if (props.borderRadius) return 'borderRadius: ' + props.borderRadius + ';'
        else return 'borderRadius: 7px;';
    }}
`;

export const StyledTableCell = styled(TableCell)<{
    fontColor?: string;
    backgroundColor?: string;
    isHeader?: boolean;
    borderBottom?: string;
    maxWidth?: string;
}>`
    &&{
        font-size: 14;
        font-weight: 600;
        font-family: 'Fira Sans';
        padding: 1rem 1rem;
        ${(props) => {
            if (props.isHeader) return 'background-color: #E8F9F4;';
            if (props.backgroundColor) return 'background-color: ' + props.backgroundColor + ';'
            else return 'background-color: #FFFFFF;';
        }}
        ${(props) => {
            if (props.fontColor) return 'color: ' + props.fontColor + ';'
            else return 'color: #132C3E;';
        }}
        ${(props) => {
            if (props.borderBottom) return 'border-bottom: ' + props.borderBottom + ';'
        }}
        ${(props) => {
            if (props.maxWidth) return 'max-width: ' + props.maxWidth + ';'
        }}
        overflow: auto;
    }
`;

