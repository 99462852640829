import 'react-circular-progressbar/dist/styles.css';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { SyntheticEvent, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useAppDispatch } from '../../../app/hooks';
import { updateActiveStudent } from '../../../app/navbarSlice';
import {
  createStudentLabelValue,
  shortenStudentName,
} from '../sharedStyledComponents';

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    color: '#132C3E',
    fontFamily: 'Fira Sans',
  },
  head: {
    backgroundColor: '#E8F9F4',
    padding: '1rem 1rem',
  },
  body: {
    padding: '.6rem 1rem',
  },
}))(TableCell);

const StyledTable = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '7px',
  },
}))(Table);

const useStyles = makeStyles({
  container: {
    overflowX: 'auto',
    width: '100%',
    maxHeight: 500,
  },
  stickyHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 90,
  },
  stickyBody: {
    position: 'sticky',
    left: 0,
    zIndex: 80,
    background: '#FFFFFF',
  },
});

const SnapshotTable = (props: {
  students: any;
  type: string;
  previousProjects?: any;
  courseValue: string;
  project: any;
  title: string;
}) => {
  const reduxDispatch = useAppDispatch();
  const classes = useStyles();

  const redirectToStudent = (student: any) => {
    reduxDispatch(updateActiveStudent(createStudentLabelValue(student)));
  };

  const [page, setPage] = useState(1);

  const maxScore = props.project.max_score || 100;
  const maxScorePercentage = 100 / maxScore;
  const positiveScore = 0.75 * maxScore;

  return (
    <StyledTableWithCaption>
      <StyledTableCaption>
        {props.title} ({props.students.length})
      </StyledTableCaption>
      <TableContainer
        className={classes.container}
        onScroll={(e: SyntheticEvent) => {
          e.preventDefault();
          const tgt = e.target as unknown as HTMLDivElement;

          if (tgt.scrollTop + 100 >= tgt.scrollHeight - tgt.clientHeight) {
            setPage(Math.min(page + 1, Math.ceil(props.students.length / 15)));
          }
        }}
      >
        <StyledTable stickyHeader aria-label="customized table">
          <caption
            style={{
              position: 'absolute',
              left: '-10000px',
              top: 'auto',
              width: '1px',
              height: '1px',
              overflow: 'hidden',
            }}
          >
            {props.title} ({props.students.length})
          </caption>
          {props.type === 'Submitters' ? (
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.stickyHeader}
                  style={{ minWidth: '7vw' }}
                >
                  Student (A-Z)
                </StyledTableCell>

                <StyledTableCell align="center" style={{ minWidth: '8vw' }}>
                  # of Submissions
                </StyledTableCell>
                <StyledTableCell align="center" style={{ minWidth: '7vw' }}>
                  # of Hours
                </StyledTableCell>

                <StyledTableCell align="center" style={{ minWidth: '7vw' }}>
                  Score
                </StyledTableCell>
              </TableRow>
            </TableHead>
          ) : (
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.stickyHeader}
                  style={{ minWidth: '7vw' }}
                >
                  Student (A-Z)
                </StyledTableCell>
                <StyledTableCell align="center" style={{ minWidth: '10vw' }}>
                  Hours on Current Project
                </StyledTableCell>
                {props.previousProjects.map(
                  (project: { label: string }, index: number) => (
                    <StyledTableCell
                      key={index}
                      align="center"
                      style={{ minWidth: '7vw' }}
                    >
                      {project.label}
                    </StyledTableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {props.students
              .slice(0, page * 15)
              .map((student: any, index: React.Key | null | undefined) => (
                <TableRow key={index}>
                  <StyledTableCell
                    className={classes.stickyBody}
                    component="th"
                    scope="row"
                  >
                    <Link
                      to={{
                        pathname: `/${props.courseValue}/${props.project.value}/students/selected-project`,
                      }}
                      onClick={() => redirectToStudent(student)}
                      data-tip={student.email}
                    >
                      {student.full_name
                        ? shortenStudentName(student.full_name)
                        : shortenStudentName(student.email)}
                      <ReactTooltip type="light" place="right" effect="solid" />
                    </Link>
                  </StyledTableCell>
                  {props.type === 'Submitters' ? (
                    <>
                      <StyledTableCell align="center">
                        {student.nSubmissions}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {student.hoursspent || '< 1'}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div style={{ margin: 'auto', width: 40, height: 40 }}>
                          <CircularProgressbar
                            value={Math.round(
                              (student.module_score || 0) * maxScorePercentage,
                            )}
                            text={`${Math.round(
                              (student.module_score || 0) * maxScorePercentage,
                            )}%`}
                            strokeWidth={10}
                            styles={{
                              path: {
                                stroke: `${
                                  student.module_score >= positiveScore
                                    ? '#5ABE72'
                                    : '#FF514A'
                                }`,
                              },
                              text: {
                                fontSize: '30px',
                                fill: '#132C3E',
                              },
                            }}
                          />
                        </div>
                      </StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell align="center">
                        {student.hoursspent || 0}
                      </StyledTableCell>
                      {student.projects &&
                        student.projects[0] &&
                        student.projects.map(
                          (
                            projectData: {
                              score: number;
                              project: { max_score?: any };
                            },
                            index: React.Key | null | undefined,
                          ) => (
                            <StyledTableCell key={index} align="center">
                              <div
                                style={{
                                  margin: 'auto',
                                  width: 40,
                                  height: 40,
                                }}
                              >
                                <CircularProgressbar
                                  value={Math.round(
                                    ((projectData.score || 0) /
                                      (projectData.project.max_score || 100)) *
                                      100,
                                  )}
                                  text={`${Math.round(
                                    ((projectData.score || 0) /
                                      (projectData.project.max_score || 100)) *
                                      100,
                                  )}%`}
                                  strokeWidth={10}
                                  styles={{
                                    path: {
                                      stroke: `${
                                        projectData.score >= positiveScore
                                          ? '#5ABE72'
                                          : '#FF514A'
                                      }`,
                                    },
                                    text: {
                                      fontSize: '30px',
                                      fill: '#132C3E',
                                    },
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                          ),
                        )}
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </StyledTableWithCaption>
  );
};

export default SnapshotTable;

const StyledTableCaption = styled.div`
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.125rem;
  padding: 0;
  margin-bottom: 0.5rem;
`;

const StyledTableWithCaption = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
