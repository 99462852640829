import { Link } from 'react-router-dom';
import styled from 'styled-components';

export function shortenStudentName(name: string) {
  if (name) {
    const names = name.split(' ');
    if (names.length > 1) {
      const shortenedName = names.map(
        (name) => (name = name.length > 10 ? `${name.slice(0, 10)}...` : name),
      );
      return shortenedName.join(' ');
    } else {
      return name.split('@')[0];
    }
  }
  return name;
}

export function createStudentLabelValue(student: any) {
  return {
    label:
      student.label ||
      student.full_name ||
      student.name ||
      student.email.split('@')[0],
    value: student.value || student.email,
  };
}

export function formatDate(date: string) {
  const dateArray = date.split('-');
  const formattedDate = `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`;
  return formattedDate;
  //
}
export const StyledComingSoon = (props: any) => {
  return (
    <ComingSoon gridClass={props.gridClass}>
      THIS FEATURE IS COMING SOON
    </ComingSoon>
  );
};

export const StyledStudent = styled(Link)`
  margin-top: 0.3rem;
  background: #ffffff;
  border: 1px solid rgba(19, 44, 62, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: left;
  width: 100%;
  padding: 0.4rem;
  color: rgba(19, 44, 62, 0.8);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const ExpandImg = styled.img`
  vertical-align: text-top;
  width: 100%;
  height: auto;
  max-width: 1.5rem;
  max-height: 1.5rem;
`;

export const Left = styled.div`
  width: 80%;
`;
export const Right = styled.div`
  width: 15%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StyledGraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem 0;
`;

export const StyledGraphButton = styled.div`
  border: 2px solid rgba(19, 44, 62, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.25rem;
  font-size: 0.75rem;
`;

export const StyledGraph = styled.div<{ width?: string }>`
  width: ${(props) => (props.width === 'full' ? '100%' : '75%')};
`;

export const StyledGraphContainer = styled.div<{
  gridClass?: string;
  maxHeight?: number;
}>`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 1rem;
  grid-area: ${(props) => props.gridClass};
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight}rem;
`;

export const StyledGraphRightColumn = styled.div`
  display: flex;
  width: 20%;
  flex-direction: column;
  font-size: 0.75rem;
`;

export const StyledGraphActiveData = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
  height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledGraphActiveDataTwo = styled.div<{ gridClass?: string }>`
  font-size: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  min-width: 11rem;
  background: #ffffff;
  padding: 1rem;
  grid-area: ${(props) => props.gridClass};
  flex-direction: column;
`;

export const StyledGraphActiveDataTwoTitle = styled.div`
  padding-bottom: 1rem;
`;

export const StyledGraphTitle = styled.div`
  padding-left: 1rem;
`;

export const ComingSoon = styled.div<{ gridClass?: string }>`
  background-color: rgba(215, 213, 213, 0.9);
  grid-area: ${(props) => props.gridClass};
  z-index: 1;
  text-align: center;
  line-height: 15rem;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  color: rgb(95, 106, 106);
`;

export const StyledNoDataResponse = styled.div`
  padding-left: 1rem;
  color: rgba(19, 44, 62, 0.7);
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 1%;
  grid-row-gap: 2%;
  height: 85%;
`;
