import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import React from 'react';

const RechartsBarChart = (props: { data: any, header: any }) => {
  const data = props.data;

  // Grouping scores by ranges of 10 points and counting occurrences
  const scoreCounts = data.reduce((acc, item) => {
    const scoreRange = Math.floor(item.module_score / 10) * 10;
    acc[scoreRange] = (acc[scoreRange] || 0) + 1;
    return acc;
  }, {});

  // Converting the scoreCounts object into an array for Recharts
  const chartData = Object.keys(scoreCounts).map(range => ({
    scoreRange: `${range}-${parseInt(range) + 9}`,
    count: scoreCounts[range],
  })).sort((a, b) => parseInt(a.scoreRange) - parseInt(b.scoreRange));

  return (
    <div>
      {props.header && <h2 style={{ textAlign: 'center' }}>{props.header}</h2>}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          layout="vertical"
          data={chartData}
          width={800}
          height={300}
          margin={{ top: 20, right: 100, left: 50, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            label={{ value: 'Number of Students', position: 'insideBottom', offset: -10 }}
            tick={{ fill: '#666' }}
          />
          <YAxis
            type="category"
            dataKey="scoreRange"
            label={{ value: 'Score Range', angle: -90, position: 'insideLeft', dx: 0  }}
            tick={{ fill: '#666', fontSize: 12}}  // Ensure text does not wrap
          />
          <Tooltip />
          <Bar dataKey="count" fill="#82ca9d">
            <LabelList dataKey="count" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RechartsBarChart;