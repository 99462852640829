import Theme from '../sharedStyles/Theme';
import styled from 'styled-components';
import { useAppSelector } from '../app/hooks';

const Footer = () => {
  const lastUpdated = useAppSelector(
    (state) => state.navbar.lastUpdatedTimestamp,
  );
  const currentYear = new Date().getFullYear();


  const formatUpdatedDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
    
    const pad = (num: number) => num.toString().padStart(2, '0');
    
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneAbbr = new Intl.DateTimeFormat('en', { timeZoneName: 'short' }).formatToParts(date)
      .find(part => part.type === 'timeZoneName')?.value || '';
  
    return `${months[date.getMonth()]} ${pad(date.getDate())} ${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())} ${timeZoneAbbr}`;
  };
  return (
    <Theme>
      <StyledFooter>
        <div>
          <StyledA
            href="https://docs.google.com/forms/d/e/1FAIpQLSdBQaLgP8h_OBsfW45-7by6w06lrq_4OZwPmC8OsOPVUXMMOQ/viewform?usp=sf_link"
            target="​_blank"
          >
            Share Your Feedback
          </StyledA>{' '}
          {lastUpdated && (
            <>| Data last updated: {formatUpdatedDate(lastUpdated)}</>
          )}
        </div>

        <div> © {currentYear} Copyright Sail() Platform</div>
      </StyledFooter>
    </Theme>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  display: flex;
  height: 2rem;
  background: #132c3e;
  color: #f2f2f2;
  font-size: 0.85rem;
  padding: 0.75rem 2rem;
  justify-content: space-between;
  > * {
    font-weight: 300;
  }
`;

const StyledA = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;
