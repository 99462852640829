import React, { useState } from 'react';

import { ButtonExpand } from '../../../svgs/button-expand';
import ReactTooltip from 'react-tooltip';
import Student from '../Student';
import Theme from '../../../sharedStyles/Theme';
import styled from 'styled-components';

const SnapshotCard = (props: {
  title: string;
  description: string;
  students: Array<any>;
}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Theme>
      <StyledSnapshotCard>
        <UnexpandedSnapshotCard
          alt={`Expand Snapshot Card for ${props.title}`}
          onClick={() => setToggle(!toggle)}
        >
          <Row>
            <StyledCount>{props.students.length}</StyledCount>
            <ButtonExpand fillOpacity={toggle ? '.2' : '.1'} />
          </Row>
          <Row>
            <StyledDescription>{props.title}</StyledDescription>
          </Row>
        </UnexpandedSnapshotCard>
        <ExpandedSnapshotCard open={toggle}>
          <InsideExpandedSnapshotCard>
            {props.description}
            {props.students.map((student, index) => (
              <Student student={student} key={index} />
            ))}
          </InsideExpandedSnapshotCard>
        </ExpandedSnapshotCard>
      </StyledSnapshotCard>
      <ReactTooltip type="light" place="right" effect="solid" />
    </Theme>
  );
};

export default SnapshotCard;

const StyledSnapshotCard = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.875rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledCount = styled.div`
  font-size: 40px;
  text-align: left;
  padding-top: 1rem;
  color: ${(props) => props.theme.colors.secondaryDark};
`;
const StyledDescription = styled.div`
  font-size: 14px;
  color: rgba(19, 44, 62, 0.6);
  text-align: left;
  padding: 0.5rem 0.5rem 0 0;
`;

const UnexpandedSnapshotCard = styled.button<{ onClick: any; alt: string }>`
  border: none;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  width: 90%;
  height: 100%;
`;

const ExpandedSnapshotCard = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  z-index: 95;
  flex-direction: column;
  position: absolute;
  top: 8rem;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 7px;

  margin-top: 0.3rem;

  width: 100%;
  height: 20rem;
  overflow-y: auto;
`;

const InsideExpandedSnapshotCard = styled.div`
  padding: 1rem 0.5rem;
`;
