import { AppThunk, RootState } from './store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AllProjectsState {
  pageStatus: boolean;
  submissionsPerProject: any;
  pageError: string;
  activityPerStudentPerProject: any;
  selectedGraphKey: any;
  activityPerActiveStudentPerProject: any;
  activityPerStudentPerProjectLoading: boolean;
}

const initialState: AllProjectsState = {
  pageStatus: false,
  submissionsPerProject: {},
  pageError: '',
  activityPerStudentPerProject: { nHours: [] },
  selectedGraphKey: {
    selectedBoxplot: { value: 'nSubmissions', label: '# of Submissions' },
  },
  activityPerActiveStudentPerProject: { nHours: [] },
  activityPerStudentPerProjectLoading: true,
};

export const allProjectsSlice = createSlice({
  name: 'allProjects',
  initialState,
  reducers: {
    clearActiveStudent: (state) => {
      state.submissionsPerProject = {};
      state.activityPerStudentPerProjectLoading = true;
    },
    updatePageStatus: (state, action: PayloadAction<boolean>) => {
      state.pageStatus = action.payload;
    },
    updateSubmissionsPerProject: (state, action: PayloadAction<any>) => {
      const newProject = action.payload;
      state.submissionsPerProject[newProject.project] = newProject.submissions;
    },
    updatePageError: (state, action: PayloadAction<any>) => {
      state.pageError = action.payload;
    },
    updateActivityPerStudentPerProject: (state, action: PayloadAction<any>) => {
      state.activityPerStudentPerProject[action.payload.type] =
        action.payload.data;
    },
    updateActivityPerActiveStudentPerProject: (
      state,
      action: PayloadAction<any>,
    ) => {
      const nHours = state.activityPerStudentPerProject['nHours'].filter(
        function (e: { email: any }) {
          return e.email === action.payload;
        },
      );

      const nSubmissions = state.activityPerStudentPerProject[
        'nSubmissions'
      ].filter(function (e: { email: any }) {
        return e.email === action.payload;
      });

      const score = state.activityPerStudentPerProject['score'].filter(
        function (e: { email: any }) {
          return e.email === action.payload;
        },
      );

      state.activityPerActiveStudentPerProject['nHours'] = nHours;
      state.activityPerActiveStudentPerProject['nSubmissions'] = nSubmissions;
      state.activityPerActiveStudentPerProject['score'] = score;
      state.activityPerStudentPerProjectLoading = false;
    },
    updateActiveStudentsGraph: (state, action: PayloadAction<any>) => {
      state.selectedGraphKey[action.payload.graphType] = action.payload.event;
    },
  },
});

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

// ****************
// APIs
// ****************

const { REACT_APP_BACKEND_URI } = process.env;

export const getActivityPerStudentPerProject =
  (): AppThunk => async (dispatch, getState) => {
    const cookieValue = getStorageValue('jwt', 'baljit');
    const courseValue = getState().navbar.activeCourse.value;
    const studentValue = getState().navbar.activeStudent.value;
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);

    if (courseValue && studentValue) {
      await Promise.all([
        fetch(`${REACT_APP_BACKEND_URI}/api/${courseValue}/course/nHours`, {
          method: 'GET',
          headers: headers,
        }),
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseValue}/course/nSubmissions`,
          {
            method: 'GET',
            headers: headers,
          },
        ),
        fetch(`${REACT_APP_BACKEND_URI}/api/${courseValue}/course/scores`, {
          method: 'GET',
          headers: headers,
        }),
      ])
        .then((responses) => {
          return Promise.all(
            responses.map(async function (response) {
              if (response.ok) return response.json();
              else {
                const errorText = await response.text();
                throw new Error(`${errorText} for ${response.url}`);
              }
            }),
          );
        })
        .then((jsonData) => {
          return jsonData.forEach(function (json, index) {
            switch (index) {
              case 0:
                dispatch(
                  updateActivityPerStudentPerProject({
                    data: json,
                    type: 'nHours',
                    activeStudent: studentValue,
                  }),
                );
                break;
              case 1:
                dispatch(
                  updateActivityPerStudentPerProject({
                    data: json,
                    type: 'nSubmissions',
                    activeStudent: studentValue,
                  }),
                );
                break;
              case 2:
                dispatch(
                  updateActivityPerStudentPerProject({
                    data: json,
                    type: 'score',
                    activeStudent: studentValue,
                  }),
                );
                break;
            }
          });
        })
        .then(() => {
          dispatch(updateActivityPerActiveStudentPerProject(studentValue));
        })

        .catch((error) => {
          dispatch(updatePageError(error.toString()));
          dispatch(updatePageStatus(true));
        })
        .then(() => {
          dispatch(updatePageStatus(true));
        });
    }
  };

export const getStudentDataForProjectSubmissions =
  (): AppThunk => async (dispatch, getState) => {
    const cookieValue = getStorageValue('jwt', 'baljit');
    const courseValue = getState().navbar.activeCourse.value;
    const projectList = getState().navbar.projects;
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    const studentValue = getState().navbar.activeStudent.value;

    if (courseValue) {
      await Promise.all(
        projectList.map((project: { value: any }) =>
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseValue}/student/ScorePerSubmission/${studentValue}/${project.value}`,
            {
              method: 'GET',
              headers: headers,
            },
          )
            .then(async (response) => {
              if (response.ok) return response.json();
              else {
                const errorText = await response.text();
                throw new Error(`${errorText} for ${response.url}`);
              }
            })
            .then((myJson) => {
              const submission = {
                project: project.value,
                submissions: myJson,
              };
              dispatch(updateSubmissionsPerProject(submission));
            })

            .catch((error) => {
              dispatch(updatePageError(error.toString()));
              dispatch(updatePageStatus(true));
            }),
        ),
      ).then(() => {
        dispatch(updatePageStatus(true));
      });
    }
  };

// **************************************************************
// Exports
// **************************************************************
export const {
  clearActiveStudent,
  updatePageStatus,
  updateSubmissionsPerProject,
  updatePageError,
  updateActivityPerStudentPerProject,
  updateActiveStudentsGraph,
  updateActivityPerActiveStudentPerProject,
} = allProjectsSlice.actions;

export const allProjectsPageError = (state: RootState) =>
  state.allProjects.pageError;
export const allProjectsSelectedGraphKey = (state: RootState) =>
  state.allProjects.selectedGraphKey;
export const allProjectsActivityPerStudentPerProject = (state: RootState) =>
  state.allProjects.activityPerStudentPerProject;
export const allProjectsActivityPerActiveStudentPerProject = (
  state: RootState,
) => state.allProjects.activityPerActiveStudentPerProject;
export const allProjectsActivityPerStudentPerProjectLoading = (
  state: RootState,
) => state.allProjects.activityPerStudentPerProjectLoading;

export default allProjectsSlice.reducer;
