import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  navbarActiveCourse,
  navbarActiveProject,
  navbarComponentError,
} from '../../app/navbarSlice';
import {
  trendActiveStudentsPerDayLoading,
  trendHoursPerStudentPerDayLoading,
  trendPageError,
  trendScorePerStudentPerDayLoading,
  trendSubmissionsPerStudentPerDayLoading,
  trendTrendProject,
  updatePageStatus,
} from '../../app/trendSlice';

import styled from 'styled-components';
import { snapshotPageStatus } from '../../app/snapshotSlice';
import ProjectsNavBar from '../../components/Projects/ProjectsNavBar';
import ActivityPerStudentPerDay from '../../components/Projects/Trend/ActivityPerStudentPerDay';
import NumberOfActiveStudents from '../../components/Projects/Trend/NumberOfActiveStudents';
import Scatterplot from '../../components/Projects/Trend/Scatterplot';
import ScatterplotSelectedStudent from '../../components/Projects/Trend/ScatterplotSelectedStudent';
import { StyledGrid } from '../../components/Projects/sharedStyledComponents';
import { LoadingLogo } from '../../svgs/loading-logo';
import { LoadingOverlay } from '../../svgs/loading-overlay';
import Error from '../Error';

const Trend = () => {
  const reduxDispatch = useAppDispatch();

  const pageStatus = useAppSelector(snapshotPageStatus);
  const project = useAppSelector(navbarActiveProject);
  const trendProject = useAppSelector(trendTrendProject);
  const courseValue = useAppSelector(navbarActiveCourse).value;
  const pageError = useAppSelector(trendPageError);
  const componentError = useAppSelector(navbarComponentError);
  const activeStudentsPerDayLoading = useAppSelector(
    trendActiveStudentsPerDayLoading,
  );
  const submissionsPerStudentPerDayLoading = useAppSelector(
    trendSubmissionsPerStudentPerDayLoading,
  );
  const hoursPerStudentPerDayLoading = useAppSelector(
    trendHoursPerStudentPerDayLoading,
  );
  const scorePerStudentPerDayLoading = useAppSelector(
    trendScorePerStudentPerDayLoading,
  );

  useEffect(() => {
    if (trendProject === project) {
      reduxDispatch(updatePageStatus(true));
    }
    return () => {
      reduxDispatch(updatePageStatus(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseValue, project]);

  return courseValue === '404' || componentError ? (
    <Error errorType={404} />
  ) : pageError ? (
    <Error errorType={500} errorMessage={pageError} />
  ) : (
    <>
      <ProjectsNavBar />
      {!pageStatus ? (
        <LoadingLogo />
      ) : (
        <StyledTrend>
          <NumberOfActiveStudents gridClass={'one'} />
          <LoadingOverlay
            view={activeStudentsPerDayLoading}
            gridClass={'one'}
          />
          <ActivityPerStudentPerDay gridClass={'two'} />
          <LoadingOverlay
            view={
              hoursPerStudentPerDayLoading &&
              scorePerStudentPerDayLoading &&
              submissionsPerStudentPerDayLoading
            }
            gridClass={'two'}
          />
          <ScatterplotSelectedStudent gridClass={'three'} />

          <Scatterplot
            gridClass={'five'}
            title="Score vs Hours Spent"
            yAxisLabel="Score (Pts)"
            xDataKey="hoursspent"
            xAxisLabel="Hours Spent (Hrs)"
            yDataKey="module_score"
          />
          <Scatterplot
            gridClass={'six'}
            title="Submissions vs Hours Spent"
            yAxisLabel="Number of Submissions"
            xDataKey="hoursspent"
            xAxisLabel="Hours Spent (Hrs)"
            yDataKey="nSubmissions"
          />
          <Scatterplot
            gridClass={'four'}
            title="Score vs Submissions"
            xAxisLabel="Number of Submissions"
            yDataKey="module_score"
            yAxisLabel="Score (Pts)"
            xDataKey="nSubmissions"
          />
        </StyledTrend>
      )}
    </>
  );
};

export default Trend;

const StyledTrend = styled(StyledGrid)`
  grid-template-columns: repeat(10, 1fr);
  grid-template-areas: 'one one one one one two two two two two' 'three four four four five five five six six six ';

  @media (max-width: ${(props) => props.theme.responsive.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'one one' 'two two' 'three four' 'five six';
  }
`;
